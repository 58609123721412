import levenshtein from "fast-levenshtein";

export const stopsRequete = async () => {
  const stops: {
    id: string;
    lignes: string[];
    name: string;
    position: number[];
  }[] = [];
  // SIRI-Lite stoppoints-discovery
  try {
    const response = await fetch(
      "https://bdx.mecatran.com/utw/ws/siri/2.0/bordeaux/stoppoints-discovery.json?AccountKey=opendata-bordeaux-metropole-flux-gtfs-rt",
    );
    const data = await response.json();
    data.Siri.StopPointsDelivery.AnnotatedStopPointRef.forEach(
      (element: {
        StopPointRef: { value: string };
        StopName: { value: string };
        Location: { longitude: number; latitude: number };
        Lines: [{ value: string }];
      }) => {
        const desserte = element.Lines.map(
          (Lelement) => Lelement.value.split(":")[2],
        );
        const stopData = {
          id: element.StopPointRef.value.split(":")[3],
          lignes: desserte,
          name: element.StopName.value,
          position: [element.Location.longitude, element.Location.latitude],
        };
        stops.push(stopData);
      },
    );
  } catch (ERRfatal) {
    console.error(ERRfatal);
    return;
  }

  // Object temporaire pour combiner les arrêts par nom
  const stopsByName: any = {};

  stops.forEach((element) => {
    if (!stopsByName[element.name]) {
      // Si l'arrêt n'existe pas dans l'objet, on l'ajoute
      stopsByName[element.name] = {
        id: [element.id],
        lignes: [...element.lignes],
        name: element.name,
        position: element.position,
      };
    } else {
      // Sinon, on met à jour les informations existantes
      if (!stopsByName[element.name].id.includes(element.id)) {
        stopsByName[element.name].id.push(element.id);
      }
      element.lignes.forEach((ligne) => {
        if (!stopsByName[element.name].lignes.includes(ligne)) {
          stopsByName[element.name].lignes.push(ligne);
        }
      });
    }
  });

  // Convertir l'objet en tableau
  const combinedStops: {
    id: string[];
    lignes: string[];
    name: string;
    position: number[];
  }[] = Object.values(stopsByName);
  return combinedStops;
};

export const routesRequete = async () => {
  // SIRI-Lite lines-discovery
  const LESroutes: {
    id: string;
    name: string;
    name_short: string;
    terminus: {
      direction: string | string[];
      id: string;
    }[];
  }[] = [];
  try {
    const response = await fetch(
      "https://bdx.mecatran.com/utw/ws/siri/2.0/bordeaux/lines-discovery.json?AccountKey=opendata-bordeaux-metropole-flux-gtfs-rt",
    );
    const data = await response.json();
    data.Siri.LinesDelivery.AnnotatedLineRef.forEach(
      (element: {
        Destinations: [
          {
            DirectionRef: { value: string };
            PlaceName: [{ value: string }];
          },
        ];
        LineCode: { value: string };
        LineName: [{ value: string }];
        LineRef: { value: string };
      }) => {
        const destinations: {
          direction: string | string[];
          id: string;
        }[] = [];
        element.Destinations.forEach((Lelement) => {
          const LESdirections: string[] = [];
          if (
            Lelement.PlaceName[0].value.split(" / ").length === 1 ||
            Lelement.PlaceName[0].value.split(" / ").length === 5 ||
            element.LineRef.value.split(":")[2] === "59" ||
            element.LineRef.value.split(":")[2] === "60" ||
            element.LineRef.value.split(":")[2] === "61" ||
            element.LineRef.value.split(":")[2] === "62"
          ) {
            if (Lelement.PlaceName[0].value.split(" / ").length === 5) {
              const element = Lelement.PlaceName[0].value.split(" / ");
              element.splice(3, 1);
              LESdirections.push(element.toString().replaceAll(",", " / "));
            } else {
              LESdirections.push(Lelement.PlaceName[0].value);
            }
          } else if (Lelement.PlaceName[0].value.split(" / ").length === 2) {
            LESdirections.push(
              Lelement.PlaceName[0].value.split(" / ")[0].length >
                Lelement.PlaceName[0].value.split(" / ")[1].length
                ? Lelement.PlaceName[0].value.split(" / ")[0]
                : Lelement.PlaceName[0].value.split(" / ")[1],
            );
          } else {
            const comparaison: number[] = [];
            comparaison.push(
              levenshtein.get(
                Lelement.PlaceName[0].value
                  .split(" / ")[0]
                  .replaceAll(" ", "")
                  .toLowerCase(),
                Lelement.PlaceName[0].value
                  .split(" / ")[1]
                  .replaceAll(" ", "")
                  .toLowerCase(),
              ),
            );
            comparaison.push(
              levenshtein.get(
                Lelement.PlaceName[0].value
                  .split(" / ")[1]
                  .replaceAll(" ", "")
                  .toLowerCase(),
                Lelement.PlaceName[0].value
                  .split(" / ")[2]
                  .replaceAll(" ", "")
                  .toLowerCase(),
              ),
            );
            if (Lelement.PlaceName[0].value.split(" / ").length === 4) {
              comparaison.push(
                levenshtein.get(
                  Lelement.PlaceName[0].value
                    .split(" / ")[2]
                    .replaceAll(" ", "")
                    .toLowerCase(),
                  Lelement.PlaceName[0].value
                    .split(" / ")[3]
                    .replaceAll(" ", "")
                    .toLowerCase(),
                ),
              );
              comparaison.push(
                levenshtein.get(
                  Lelement.PlaceName[0].value
                    .split(" / ")[3]
                    .replaceAll(" ", "")
                    .toLowerCase(),
                  Lelement.PlaceName[0].value
                    .split(" / ")[0]
                    .replaceAll(" ", "")
                    .toLowerCase(),
                ),
              );
            } else {
              comparaison.push(
                levenshtein.get(
                  Lelement.PlaceName[0].value
                    .split(" / ")[2]
                    .replaceAll(" ", "")
                    .toLowerCase(),
                  Lelement.PlaceName[0].value
                    .split(" / ")[0]
                    .replaceAll(" ", "")
                    .toLowerCase(),
                ),
              );
            }

            const qqch =
              Lelement.PlaceName[0].value.split(" / ").length === 4 ? 2 : 1;
            for (let i = 1; i <= qqch; i++) {
              const minimun = Math.min(...comparaison);
              LESdirections.push(
                Lelement.PlaceName[0].value.split(" / ")[
                  comparaison.indexOf(minimun)
                ].length >
                  Lelement.PlaceName[0].value.split(" / ")[
                    comparaison.indexOf(minimun) + 1 === comparaison.length
                      ? 0
                      : comparaison.indexOf(minimun) + 1
                  ].length
                  ? Lelement.PlaceName[0].value.split(" / ")[
                      comparaison.indexOf(minimun)
                    ]
                  : Lelement.PlaceName[0].value.split(" / ")[
                      comparaison.indexOf(minimun) + 1
                    ],
              );
              comparaison.splice(comparaison.indexOf(minimun), 1);
            }
            if (qqch === 1) {
              if (
                levenshtein.get(
                  LESdirections[0],
                  Lelement.PlaceName[0].value
                    .split(" / ")
                    [
                      comparaison.indexOf(Math.max(...comparaison))
                    ].replaceAll(" ", "")
                    .toLowerCase(),
                ) >
                levenshtein.get(
                  LESdirections[0],
                  Lelement.PlaceName[0].value
                    .split(" / ")
                    [
                      comparaison.indexOf(Math.min(...comparaison))
                    ].replaceAll(" ", "")
                    .toLowerCase(),
                )
              ) {
                LESdirections.push(
                  Lelement.PlaceName[0].value.split(" / ")[
                    comparaison.indexOf(Math.min(...comparaison))
                  ],
                );
              } else if (
                levenshtein.get(
                  LESdirections[0],
                  Lelement.PlaceName[0].value
                    .split(" / ")
                    [
                      comparaison.indexOf(Math.min(...comparaison))
                    ].replaceAll(" ", "")
                    .toLowerCase(),
                ) >
                levenshtein.get(
                  LESdirections[0],
                  Lelement.PlaceName[0].value
                    .split(" / ")
                    [
                      comparaison.indexOf(Math.max(...comparaison))
                    ].replaceAll(" ", "")
                    .toLowerCase(),
                )
              ) {
                LESdirections.push(
                  Lelement.PlaceName[0].value.split(" / ")[
                    comparaison.indexOf(Math.max(...comparaison))
                  ],
                );
              }
            }
          }
          destinations.push({
            direction: LESdirections,
            id: Lelement.DirectionRef.value,
          });
        });
        const data = {
          id: element.LineRef.value.split(":")[2],
          terminus: destinations,
          name_short: element.LineCode.value,
          name: element.LineName[0].value,
        };
        LESroutes.push(data);
      },
    );
  } catch (ERRfatal) {
    console.error(ERRfatal);
    return;
  }

  return LESroutes;
};

export const shapesRequete = async () => {
  // shapes.txt au format GeoJSON
  const shapes: object[] = [];
  try {
    const response = await fetch(
      "https://transport-data-gouv-fr-resource-history-prod.cellar-c2.services.clever-cloud.com/conversions/gtfs-to-geojson/81391/81391.20240930.061601.079148.zip.geojson",
    );
    const LESdata: GeoJSON.FeatureCollection = await response.json();
    LESdata.features.forEach((element) => {
      if (element.geometry.type === "LineString") {
        const data: GeoJSON.Feature = {
          geometry: {
            coordinates: element.geometry.coordinates.map((element) => {
              return [element[1], element[0]];
            }),
            type: "LineString",
          },
          properties: {
            routeId: element.properties?.route_id,
            routeColor: element.properties?.route_color,
          },
          type: "Feature",
        };
        shapes.push(data);
      }
    });
  } catch (ERRfatal) {
    console.error(ERRfatal);
    return;
  }

  return shapes;
};
