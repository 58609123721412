import { useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBug,
  faCheck,
  faCircleInfo,
  faDownload,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { saveFile } from "./deps/OPFS";
import { Link } from "react-router-dom";
import { routesRequete, shapesRequete, stopsRequete } from "./deps/DoNotOPFS";

const pause = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const initialSteps = [
  {
    id: "01",
    name: "Site",
    description:
      "Permet de sauvegarder le thème du site et la version utilisée",
    status: "upcoming",
  },
  {
    id: "02",
    name: "Favoris",
    description: "Permet de sauvegarder tes lignes et arrêts favoris",
    status: "upcoming",
  },
  {
    id: "03",
    name: "Options",
    description: "Permet de sauvegarder les différentes options disponibles",
    status: "upcoming",
  },
];
if (!isSafari && navigator.storage) {
  initialSteps.push({
    id: "04",
    name: "Téléchargement fichiers",
    description:
      "Stocke des fichiers nécessaires pour une utilisation plus rapide du site",
    status: "upcoming",
  });
}

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

const LAPage = () => {
  const [loading, setLoading] = useState<null | boolean | undefined>(null);
  const [steps, setSteps] = useState(initialSteps);
  const [erreur, setErreur] = useState<[boolean, null | any]>([false, null]);
  const [view, setView] = useState<boolean>(false);
  const [pourcentage, setPourcentage] = useState<number>(0);
  const Site = localStorage.getItem("Site");

  useEffect(() => {
    const initialize = async () => {
      for (const step of steps) {
        setSteps((prevSteps) =>
          prevSteps.map((s) =>
            s.id === step.id ? { ...s, status: "current" } : s,
          ),
        );
        switch (step.id) {
          case "01":
            localStorage.setItem("Site", "810");
            break;
          case "02":
            localStorage.setItem("LignesFavorites", JSON.stringify([]));
            localStorage.setItem("ArretsFavoris", JSON.stringify({}));
            break;
          case "03":
            const Options: { [key: string]: boolean } = {
              modele: false,
              exploitant: false,
              vitesse: false,
              arret: false,
            };
            localStorage.setItem("Options", JSON.stringify(Options));
            break;
          case "04":
            // stops.json
            const requete1 = await stopsRequete();
            if (!requete1) {
              setErreur([true, "TypeError: Failed to fetch"]);
            } else {
              await saveFile("stops.json", requete1);
            }

            // routes.json
            const requete2 = await routesRequete();
            if (!requete2) {
              setErreur([true, "TypeError: Failed to fetch"]);
            } else {
              await saveFile("routes.json", requete2);
            }

            // shapes.json
            const requete3 = await shapesRequete();
            if (!requete3) {
              setErreur([true, "TypeError: Failed to fetch"]);
            } else {
              await saveFile("shapes.json", requete3);
            }

            const mtn = Date.now().toString();
            const OPFS = {
              routes: mtn,
              shapes: mtn,
              stops: mtn,
            };
            localStorage.setItem("OPFS", JSON.stringify(OPFS));
            break;
        }
        setSteps((prevSteps) =>
          prevSteps.map((s) =>
            s.id === step.id ? { ...s, status: "complete" } : s,
          ),
        );
      }
      setLoading(false);
    };

    const maj = async () => {
      for (let i = 0; i <= 100; i += 25) {
        setPourcentage(i);
        await pause(1000);
      }
      localStorage.setItem("Site", "810");
      setLoading(false);
    };

    if (loading === true) {
      initialize();
    } else if (loading === undefined) {
      maj();
    }
    // eslint-disable-next-line
  }, [loading]);

  return (
    <main>
      {erreur[0] && (
        <Dialog
          open={true}
          onClose={() => setErreur([false, null])}
          className="relative z-10"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-300 sm:mx-0 sm:h-10 sm:w-10">
                      <FontAwesomeIcon icon={faBug} />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Erreur
                      </DialogTitle>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Une erreur{" "}
                          <b>
                            {String(erreur[1]).includes("fetch")
                              ? "lors d'une requête"
                              : "inconnue"}
                          </b>{" "}
                          est survenue.{" "}
                          {String(erreur[1]).includes("fetch")
                            ? "Vérifie ta connexion Internet et actualise la page"
                            : "Patiente quelques instants puis actualise la page"}
                          <br />
                          <span
                            className="flex justify-start text-blue-500"
                            onClick={() => setView(!view)}
                          >
                            {view ? (
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                className="mr-1"
                              />
                            ) : (
                              <FontAwesomeIcon icon={faEye} className="mr-1" />
                            )}
                            {view
                              ? "Cacher les détails"
                              : "Afficher les détails"}
                          </span>
                          {view && (
                            <span className="flex justify-start text-sm italic text-gray-500">
                              {String(erreur[1])}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <Link to="/Init" onClick={() => window.location.reload()}>
                    {" "}
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-300 sm:ml-3 sm:w-auto"
                    >
                      Actualiser la page
                    </button>
                  </Link>
                  {loading === true && (
                    <button
                      type="button"
                      onClick={() => {
                        setErreur([false, null]);
                        setLoading(false);
                      }}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Utiliser le site sans télécharger les fichiers (plus lent)
                    </button>
                  )}
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      )}
      {loading === null || loading === undefined ? (
        <Dialog open={true} onClose={() => true} className="relative z-10">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      {Site && parseInt(Site) < 810 ? (
                        <FontAwesomeIcon icon={faDownload} />
                      ) : (
                        <FontAwesomeIcon icon={faCircleInfo} />
                      )}
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {Site && parseInt(Site) < 810 ? (
                          <span>
                            Mise à jour du site disponible ! (
                            <i>
                              Version{" "}
                              {Site.split("")[Site.split("").length - 1] === "0"
                                ? Site.split("")
                                    .slice(0, Site.split("").length - 1)
                                    .toString()
                                    .replaceAll(",", ".")
                                : Site.split("")
                                    .toString()
                                    .replaceAll(",", ".")}{" "}
                              ➡️ <u>8.1</u>
                            </i>{" "}
                            )
                          </span>
                        ) : (
                          <span>1ère utilisation</span>
                        )}
                      </DialogTitle>
                      <div className="mt-2">
                        <span className="text-sm text-gray-500">
                          {Site && parseInt(Site) < 810 ? (
                            <>
                              <span>
                                🆕 Une nouvelle version est disponible !
                              </span>
                              <br />
                              <span>
                                Pour profiter de cette nouvelle version, nous
                                mettons à jour certains éléments (
                                <i>
                                  aucun impact sur tes options/arrêts favoris...
                                </i>
                                )
                              </span>
                              <span
                                className="flex justify-start text-blue-500"
                                onClick={() => setView(!view)}
                              >
                                {view ? (
                                  <FontAwesomeIcon
                                    icon={faEyeSlash}
                                    className="mr-1"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faEye}
                                    className="mr-1"
                                  />
                                )}
                                {view
                                  ? "Cacher les nouveautés"
                                  : "Afficher les nouveautés"}
                              </span>
                              {view && (
                                <span className="mt-3 flex justify-start text-sm text-gray-500">
                                  {/*
                                  - Quand une des meilleures nouveautés : 
                                    <span className="me-2 rounded-full bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-800">
                                      Meilleure nouveauté
                                    </span>
                                  - Quand une nouveauté : 
                                    <span className="me-2 rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                                      Nouveau
                                    </span>
                                  - Quand une correction :
                                    <span className="me-2 rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                                      Correction
                                    </span>
                                  - Quand une autre information :
                                    <span className="me-2 rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-80">
                                      Autre
                                    </span>
                                  */}
                                  <ul>
                                    <li>
                                      <span className="me-2 rounded-full bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-800">
                                        Meilleure nouveauté
                                      </span>{" "}
                                      Mise à niveau de l'Info Trafic
                                    </li>
                                    <li>
                                      <span className="me-2 rounded-full bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-800">
                                        Meilleure nouveauté
                                      </span>{" "}
                                      L'interface de la liste des arrêts a été
                                      refaite les arrêts sont triés correctement
                                    </li>
                                    <li>
                                      <span className="me-2 rounded-full bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-800">
                                        Meilleure nouveauté
                                      </span>{" "}
                                      Les performances ont été augmentées de 50%
                                      et plus
                                    </li>
                                    <li>
                                      <span className="me-2 rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                                        Nouveau
                                      </span>{" "}
                                      La popup d'erreur s'adapte en fonction de
                                      ce qui s'est passé
                                    </li>
                                    <li>
                                      <span className="me-2 rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                                        Nouveau
                                      </span>{" "}
                                      Il est désormais indiqué dans des
                                      prochains passages sont des horaires
                                      théoriques (
                                      <i>
                                        souvent, c'est quand il n'y a pas
                                        l'icône Wi-Fi clignotante
                                      </i>
                                      )
                                    </li>
                                    <li>
                                      <span className="me-2 rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                                        Nouveau
                                      </span>{" "}
                                      Les marques des véhicules sont affichées
                                      désormais en couleur pour les mettre en
                                      évidence
                                    </li>
                                    <li>
                                      <span className="me-2 rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                                        Nouveau
                                      </span>{" "}
                                      Remplacement `0 min` ➡️ `Proche` sur les
                                      Prochains Passages
                                    </li>
                                    <li>
                                      <span className="me-2 rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                                        Correction
                                      </span>{" "}
                                      lors du déplacement de l'ordre de tes
                                      lignes/arrêts favoris (<i>Paramètres</i>)
                                    </li>
                                    <li>
                                      <span className="me-2 rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                                        Correction
                                      </span>{" "}
                                      de l'affichage lorsqu'un véhicule a un
                                      départ au terminus retardé (<i>Carte</i>)
                                    </li>
                                    <li>
                                      <span className="me-2 rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                                        Correction
                                      </span>{" "}
                                      de l'affichage intrusif de la popup
                                      d'erreur lorsque celle-ci s'affiche (
                                      <i>Accueil</i>)
                                    </li>
                                    <li>
                                      <span className="me-2 rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                                        Correction
                                      </span>{" "}
                                      de divers bugs au niveaau des BRT (
                                      <i>Carte</i>)
                                    </li>
                                    <li>
                                      <span className="me-2 rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                                        Correction
                                      </span>{" "}
                                      de la mauvaise affichage des modèles sur
                                      certaines lignes (<i>Carte</i>)
                                    </li>
                                    <li>
                                      <span className="text-gray-80 me-2 rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium">
                                        Autre
                                      </span>{" "}
                                      Désormais lorsque l'utilisateur utilise
                                      toujours l'ancienne version de TBpM, ces
                                      données seront supprimées pour éviter des
                                      erreurs
                                    </li>
                                  </ul>
                                </span>
                              )}
                            </>
                          ) : (
                            <>
                              <span>👋 Bienvenue !</span>
                              <br />
                              <span>
                                Nous allons configurer ton navigateur afin de
                                permettre le fonctionnement correct du site
                              </span>
                              <br />
                              <span className="font-bold text-red-400">
                                Les paramètres que tu définis seront uniquement
                                enregistrés dans{" "}
                                <u>
                                  le navigateur que tu utilises actuellement
                                </u>
                              </span>
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row-reverse bg-gray-50 px-6 py-3">
                  {loading === undefined ? (
                    <div className="h-6 w-full rounded-full bg-gray-200">
                      <div
                        className="h-6 rounded-full bg-green-600 p-0.5 text-center text-lg font-medium leading-none text-green-100"
                        style={{ width: `${pourcentage}%` }}
                      >
                        {" "}
                        {pourcentage}%
                      </div>
                    </div>
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        if (Site && parseInt(Site) < 810) {
                          setLoading(undefined);
                        } else {
                          setLoading(true);
                        }
                      }}
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      OK
                    </button>
                  )}
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      ) : loading === true ? (
        <div className="bg-white lg:border-b lg:border-t lg:border-gray-200">
          <nav
            className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
            aria-label="Progress"
          >
            <ol className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200">
              {steps.map((step, stepIdx) => (
                <li
                  key={step.id}
                  className="relative overflow-hidden lg:flex-1"
                >
                  <div
                    className={classNames(
                      stepIdx === 0 ? "rounded-t-md border-b-0" : "",
                      stepIdx === steps.length - 1
                        ? "rounded-b-md border-t-0"
                        : "",
                      "overflow-hidden border border-gray-200 lg:border-0",
                    )}
                  >
                    {step.status === "complete" ? (
                      <>
                        <span
                          className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                          aria-hidden="true"
                        />
                        <span
                          className={classNames(
                            stepIdx !== 0 ? "lg:pl-9" : "",
                            "flex items-start px-6 py-5 text-sm font-medium",
                          )}
                        >
                          <span className="flex-shrink-0">
                            <span className="flex h-10 w-10 items-center justify-center rounded-full bg-green-600">
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          </span>
                          <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                            <span className="text-xs font-semibold uppercase tracking-wide">
                              {step.name}
                            </span>
                            <span className="text-sm font-medium text-gray-500">
                              {step.description}
                            </span>
                          </span>
                        </span>
                      </>
                    ) : step.status === "current" ? (
                      <>
                        <span
                          className="absolute left-0 top-0 h-full w-1 bg-indigo-600 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                          aria-hidden="true"
                        />
                        <span
                          className={classNames(
                            stepIdx !== 0 ? "lg:pl-9" : "",
                            "flex items-start px-6 py-5 text-sm font-medium",
                          )}
                        >
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              className="h-8 w-8 animate-spin fill-blue-600 text-gray-200"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          </div>
                          <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                            <span className="text-xs font-semibold uppercase tracking-wide text-indigo-600">
                              {step.name}
                            </span>
                            <span className="text-sm font-medium text-gray-500">
                              {step.description}
                            </span>
                          </span>
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                          aria-hidden="true"
                        />
                        <span
                          className={classNames(
                            stepIdx !== 0 ? "lg:pl-9" : "",
                            "flex items-start px-6 py-5 text-sm font-medium",
                          )}
                        >
                          <span className="flex-shrink-0">
                            <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                              <span className="text-gray-500">{step.id}</span>
                            </span>
                          </span>
                          <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-500">
                              {step.name}
                            </span>
                            <span className="text-sm font-medium text-gray-500">
                              {step.description}
                            </span>
                          </span>
                        </span>
                      </>
                    )}

                    {stepIdx !== 0 ? (
                      <>
                        {/* Separator */}
                        <div
                          className="absolute inset-0 left-0 top-0 hidden w-3 lg:block"
                          aria-hidden="true"
                        >
                          <svg
                            className="h-full w-full text-gray-300"
                            viewBox="0 0 12 82"
                            fill="none"
                            preserveAspectRatio="none"
                          >
                            <path
                              d="M0.5 0V31L10.5 41L0.5 51V82"
                              stroke="currentcolor"
                              vectorEffect="non-scaling-stroke"
                            />
                          </svg>
                        </div>
                      </>
                    ) : null}
                  </div>
                </li>
              ))}
            </ol>
          </nav>
        </div>
      ) : (
        <Dialog
          open={true}
          onClose={() => setLoading(false)}
          className="relative z-10"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        <span>Terminé !</span>
                      </DialogTitle>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Tu peux désormais utiliser le site
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <Link
                    to="/Accueil"
                    onClick={() =>
                      setTimeout(() => {
                        window.location.reload();
                      }, 1)
                    }
                  >
                    {" "}
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    >
                      C'est parti
                    </button>
                  </Link>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      )}
    </main>
  );
};

export default LAPage;
