export const deleteFile = async (fileName: string) => {
  // Access the root directory of the OPFS
  const root = await navigator.storage.getDirectory();

  // Get a handle to the file
  const fileHandle = await root.getFileHandle(fileName);

  // Remove the file
  await root.removeEntry(fileHandle.name);
};

export const readFile = async (filename: string) => {
  try {
    // Obtenir un handle pour le répertoire privé
    const root = await navigator.storage.getDirectory();

    // Obtenir un handle pour le fichier
    const fileHandle = await root.getFileHandle(filename);

    // Obtenir le fichier
    const file = await fileHandle.getFile();

    // Lire le contenu du fichier
    const content = await file.text();

    return JSON.parse(content);
  } catch {
    return null;
  }
};

export const saveFile = async (filename: string, content: object[]) => {
  // Obtenir un handle pour le répertoire privé
  const root = await navigator.storage.getDirectory();

  // Créer ou obtenir un handle pour le fichier
  const fileHandle = await root.getFileHandle(filename, { create: true });

  // Créer un stream writable pour écrire le contenu
  const writable = await fileHandle.createWritable();

  // Écrire le contenu
  await writable.write(
    new Blob([JSON.stringify(content)], { type: "application/json" }),
  );

  // Fermer le stream
  await writable.close();
};
