import { Feature } from "geojson";

type typeVehicule = {
  arretAct: string;
  direction: string;
  gid: string;
  ligne: string[];
  numArret: number;
  statut: string;
  vitesse: number;
};

type typeHoraires = [
  {
    id: string;
    tripUpdate: {
      stopTimeUpdate: [
        {
          arrival?: {
            delay: number;
            time: string;
          };
          departure?: {
            delay: number;
            time: string;
          };
          scheduleRelationship: string;
          stopId: string;
          stopSequence: number;
        },
      ];
      timestamp: string;
      trip: {
        directionId: number;
        routeId: string;
        scheduleRelationship: string;
        startDate: string;
        tripId: string;
      };
      vehicle?: {
        id: string;
        label: string;
      };
    };
  },
];

type typeRows = [
  {
    id: string[];
    lignes: string[];
    name: string;
    position: number[];
  },
];

export const statutVehicule = (
  vehicule: Feature<GeoJSON.Geometry, typeVehicule>,
  horaires: typeHoraires,
  rows: typeRows,
) => {
  let tripId,
    trip_Update,
    currentStopSequence: number,
    stopTimeUpdate,
    statutVeh,
    couleurStatut,
    dateDepart,
    DepartMnt,
    LEretard,
    stopId: string,
    stopRow,
    stopName,
    vehicules;

  const now = new Date();
  tripId = vehicule.id;
  if (tripId === undefined || typeof tripId === "number") {
    return { statutVeh: "❓ Inconnu", couleurStatut: "" };
  }

  // Trouver la mise à jour de voyage correspondante
  trip_Update = horaires.find((entity) => entity.id.includes(tripId));
  if (!trip_Update) {
    return {
      statutVeh: "‼️ <b>Statut indisponible</b>",
      couleurStatut: "rgb(214,25,33)",
    };
  }

  const isAtTerminus =
    (vehicule.properties.numArret ===
      trip_Update.tripUpdate.stopTimeUpdate.length &&
      vehicule.properties.statut !== "IN_TRANSIT_TO") ||
    (vehicule.properties.numArret === 1 &&
      vehicule.properties.statut !== "IN_TRANSIT_TO");

  if (isAtTerminus) {
    statutVeh = "🚏 Au terminus";
    couleurStatut = "grey";

    let firstStopTimeUpdate = trip_Update.tripUpdate.stopTimeUpdate.find(
      (update) => update.stopSequence === 1,
    );
    if (firstStopTimeUpdate && firstStopTimeUpdate.departure) {
      if (firstStopTimeUpdate.departure.delay >= 60) {
        statutVeh += getFormattedDepartureStatus(
          NaN,
          firstStopTimeUpdate.departure.delay,
        );
      } else {
        dateDepart = new Date(
          parseInt(firstStopTimeUpdate.departure.time) * 1000,
        );
        DepartMnt = (dateDepart.getTime() - now.getTime()) / 1000;
        LEretard = String(Math.abs(DepartMnt));
        statutVeh += getFormattedDepartureStatus(DepartMnt, LEretard);
      }
    } else {
      statutVeh += `, départ du véhicule inconnu`;
    }
  } else {
    currentStopSequence = vehicule.properties.numArret;
    stopTimeUpdate = trip_Update.tripUpdate.stopTimeUpdate.find(
      (update) => update.stopSequence === currentStopSequence,
    );

    if (stopTimeUpdate && stopTimeUpdate.arrival) {
      stopId = vehicule.properties.arretAct;
      vehicules = stopTimeUpdate.arrival.delay;
    }

    if (
      trip_Update.tripUpdate.stopTimeUpdate[currentStopSequence - 2]
        ?.scheduleRelationship === "SKIPPED"
    ) {
      stopRow = rows.find((element) => element.id.includes(stopId));
      stopName = stopRow ? stopRow.name : "❓ Inconnu";
      statutVeh = `🔀 Déviation jusqu'à l'arrêt <b>${stopName}</b>`;
      couleurStatut = "purple";
    } else if (vehicules) {
      statutVeh = getDelayStatus(vehicules);
      couleurStatut = getColorBasedOnStatus(statutVeh);
    } else {
      statutVeh = "❓ Inconnu";
      couleurStatut = "";
    }
  }

  return { statutVeh, couleurStatut };
};

const getFormattedDepartureStatus = (
  DepartMnt: number,
  LEretard: string | number,
) => {
  if (Number.isNaN(DepartMnt) && typeof LEretard === "number") {
    LEretard /= 60;
    return `, <b>départ retardé de ${Math.round(LEretard)} min</b>`;
  } else {
    let plwhile = parseInt(
      typeof LEretard === "string" ? LEretard : String(LEretard),
    );
    if (plwhile >= 60) {
      while (plwhile >= 60) {
        if (plwhile / 60 < 60) {
          LEretard = Math.round(plwhile / 60) + " min";
          plwhile /= 60;
        } else {
          const heures = Math.abs(plwhile / 60);
          const minutes = plwhile % 60;
          LEretard = `${heures} h ${minutes} min`;
          plwhile = heures;
        }
      }
    } else {
      LEretard = Math.round(plwhile / 60) + " min";
    }

    DepartMnt /= 60;
    if (DepartMnt > -1 && DepartMnt < 1) {
      return ", départ imminent";
    } else {
      return `, départ dans ${LEretard}`;
    }
  }
};

const getDelayStatus = (vehicule: number) => {
  let plwhile = Math.abs(vehicule);
  let LEretard = String(plwhile);
  while (plwhile >= 60) {
    if (plwhile / 60 < 60) {
      LEretard = Math.round(plwhile / 60) + " min";
      plwhile /= 60;
    } else {
      const heures = Math.abs(plwhile / 60);
      const minutes = plwhile % 60;
      LEretard = `${heures} h ${minutes} min`;
      plwhile = heures;
    }
  }

  if (vehicule > -60 && vehicule < 60) {
    return "🟢 À l'heure";
  } else if (vehicule >= 60) {
    return "⚠️ En <b><u>retard</u></b> de " + LEretard;
  } else {
    return "⚠️ En <b><u>avance</u></b> de " + LEretard;
  }
};

const getColorBasedOnStatus = (statutVeh: string | string[]) => {
  if (statutVeh.includes("À l'heure")) {
    return "green";
  } else if (statutVeh.includes("retard")) {
    return "orange";
  } else if (statutVeh.includes("avance")) {
    return "red";
  } else {
    return;
  }
};
