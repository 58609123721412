import { useState, useEffect } from "react";
import {
  faBug,
  faBusSimple,
  faChevronLeft,
  faCircleCheck,
  faCircleInfo,
  faCircleQuestion,
  faCircleRight,
  faEye,
  faEyeSlash,
  faFire,
  faMoon,
  faSchool,
  faShip,
  faTrain,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { Link, useLocation } from "react-router-dom";

type InfoTrafic = {
  TODAY: {
    lines: [
      {
        code: string;
        iconUrl: string;
        id: string;
        isOperating: boolean;
        isSpecial: false;
        mode: string;
        name: string;
        severity: string;
        style: {
          color: string;
          textColor: string;
        };
      },
    ];
    mode: string;
    name: string;
  }[];
  FUTURE: {
    lines: [
      {
        code: string;
        iconUrl: string;
        id: string;
        isOperating: boolean;
        isSpecial: false;
        mode: string;
        name: string;
        severity: string;
        style: {
          color: string;
          textColor: string;
        };
      },
    ];
    mode: string;
    name: string;
  }[];
};

type InfoLigne = {
  TODAY: {
    endDate: string | null;
    eventBanner: boolean;
    id: string;
    isPartOfMajorEvent: boolean;
    severity: string;
    startDate: string;
    title: string;
  }[];
  FUTURE: {
    endDate: string | null;
    eventBanner: boolean;
    id: string;
    isPartOfMajorEvent: boolean;
    severity: string;
    startDate: string;
    title: string;
  }[];
};

type InfoId = {
  content: null | string;
  description: string;
  endDate: string | null;
  eventBanner: boolean;
  eventUpdates: null | string;
  id: string;
  imageUrl: string | null;
  isPartOfMajorEvent: boolean;
  line: {
    code: string;
    iconUrl: string;
    id: string;
    isOperating: boolean;
    isSpecial: boolean;
    mode: string;
    name: string;
    style: {
      color: string;
      textColor: string;
    };
  };
};

const LignesFavoritesString = localStorage.getItem("LignesFavorites");
const LignesFavorites: string[][] = LignesFavoritesString
  ? JSON.parse(LignesFavoritesString)
  : [];

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

const LAPage = () => {
  const location = useLocation();
  const [feedEntities, setFeedEntities] = useState<InfoTrafic | null>(null);
  const [feedLigne, setLigne] = useState<InfoLigne | null>(null);
  const [feedId, setId] = useState<InfoId | null>(null);
  const [AorF, setAorF] = useState<string>("A");
  const ligneChoise = new URLSearchParams(location.search).get("ligne");
  const InfoChoise = new URLSearchParams(location.search).get("info");
  const [erreur, setErreur] = useState<[boolean, null | any]>([false, null]);
  const [view, setView] = useState<boolean>(false);

  const tabs = [
    { name: "En ce moment", current: AorF === "A" },
    { name: "Prochainement", current: AorF === "F" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await fetch(
          "https://gateway-apim.infotbm.com/maas-web/web/v1/traffic-info?filter=TODAY",
        );
        const data1 = await response1.json();
        const response2 = await fetch(
          "https://gateway-apim.infotbm.com/maas-web/web/v1/traffic-info?filter=FUTURE",
        );
        const data2 = await response2.json();
        setFeedEntities({
          TODAY: data1.global.modes,
          FUTURE: data2.global.modes,
        });
      } catch (ERRfatal) {
        console.error(ERRfatal);
        setErreur([true, ERRfatal]);
      }
    };

    const fetchLigne = async (ligne: string) => {
      try {
        const response1 = await fetch(
          `https://gateway-apim.infotbm.com/maas-web/web/v1/traffic-info/lines/${ligne}?filter=TODAY`,
        );
        const data1 = await response1.json();
        const response2 = await fetch(
          `https://gateway-apim.infotbm.com/maas-web/web/v1/traffic-info/lines/${ligne}?filter=FUTURE`,
        );
        const data2 = await response2.json();
        setLigne({
          TODAY: data1.events,
          FUTURE: data2.events,
        });
      } catch (ERRfatal) {
        console.error(ERRfatal);
        setErreur([true, ERRfatal]);
      }
    };

    const fetchInfo = async (ligne: string, id: string) => {
      try {
        const response = await fetch(
          `https://gateway-apim.infotbm.com/maas-web/web/v1/traffic-info/lines/${ligne}/events/${id}`,
        );
        const data = await response.json();
        setId(data);
      } catch (ERRfatal) {
        console.error(ERRfatal);
        setErreur([true, ERRfatal]);
      }
    };

    if (!feedEntities && !erreur[0]) {
      fetchData();
    } else if (ligneChoise && !InfoChoise && !erreur[0]) {
      if (feedLigne) {
        setLigne(null);
      }
      fetchLigne(ligneChoise);
    } else if (ligneChoise && InfoChoise && !erreur[0]) {
      if (feedId) {
        setId(null);
      }
      fetchInfo(ligneChoise, InfoChoise);
    }
    // eslint-disable-next-line
  }, [
    feedEntities,
    erreur,
    ligneChoise,
    InfoChoise,
    setErreur,
    setFeedEntities,
    setLigne,
    setId,
  ]);

  const renderRoutes = (filterCondition: (id: number) => boolean) => {
    if (feedEntities) {
      return feedEntities[AorF === "A" ? "TODAY" : "FUTURE"].map((element) => {
        return element.lines
          .filter((element) =>
            filterCondition(parseInt(element.id.split(":")[2])),
          )
          .map((element) => (
            <Link key={element.id} to={`/InfoTrafic?ligne=${element.id}`}>
              <img
                className="lignes m-2 inline-flex"
                src={`https://carto-maas.infotbm.com/assets/images/lines/${element.code}.svg`}
                title={`Logo : ${element.name}`}
                alt={`Logo : ${element.name}`}
              />
            </Link>
          ));
      });
    }
  };

  return (
    <main>
      {erreur[0] && (
        <Dialog open={true} onClose={() => true} className="relative z-10">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-300 sm:mx-0 sm:h-10 sm:w-10">
                      <FontAwesomeIcon icon={faBug} />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Erreur
                      </DialogTitle>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Une erreur{" "}
                          <b>
                            {String(erreur[1]).includes("fetch")
                              ? "lors d'une requête"
                              : "inconnue"}
                          </b>{" "}
                          est survenue.{" "}
                          {String(erreur[1]).includes("fetch")
                            ? "Vérifie ta connexion Internet et actualise la page"
                            : "Patiente quelques instants puis actualise la page"}
                          <br />
                          <span
                            className="flex justify-start text-blue-500"
                            onClick={() => setView(!view)}
                          >
                            {view ? (
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                className="mr-1"
                              />
                            ) : (
                              <FontAwesomeIcon icon={faEye} className="mr-1" />
                            )}
                            {view
                              ? "Cacher les détails"
                              : "Afficher les détails"}
                          </span>
                          {view && (
                            <span className="flex justify-start text-sm italic text-gray-500">
                              {String(erreur[1])}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <Link
                    to="/InfoTrafic"
                    onClick={() => window.location.reload()}
                  >
                    {" "}
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-300 sm:ml-3 sm:w-auto"
                    >
                      Actualiser la page
                    </button>
                  </Link>
                  <Link to="/Accueil">
                    {" "}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Retour à l'accueil
                    </button>
                  </Link>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      )}
      {(ligneChoise || InfoChoise) && (
        <Link to="/InfoTrafic">
          <button
            type="button"
            className="mb-2 me-2 rounded-full bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
            title="Choisir une autre ligne"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </Link>
      )}
      {!InfoChoise && (
        <nav aria-label="Tabs" className="flex justify-center space-x-4">
          {tabs.map((tab) => (
            <span
              key={tab.name}
              aria-current={tab.current ? "page" : undefined}
              className={classNames(
                tab.current
                  ? "bg-teal-200 text-sky-700"
                  : "bg-gray-300 text-gray-700",
                "rounded-md px-3 py-2 text-sm font-medium",
              )}
              onClick={() =>
                !tab.current && (AorF === "A" ? setAorF("F") : setAorF("A"))
              }
            >
              {tab.name}
            </span>
          ))}
        </nav>
      )}
      {!ligneChoise ? (
        <>
          {LignesFavorites.length > 1 && (
            <>
              <h3 className="mt-4 text-2xl font-bold leading-6 text-gray-900">
                Tes lignes favorites :
              </h3>
              {LignesFavorites.map((element) => (
                <Link
                  key={element[0]}
                  to={`/InfoTrafic?ligne=line:BMA:${element[0]}`}
                >
                  <div className="mt-3 flex flex-col rounded-lg bg-white py-2">
                    <span>
                      {feedEntities ? (
                        feedEntities[AorF === "A" ? "TODAY" : "FUTURE"].flatMap(
                          (Lelement) =>
                            Lelement.lines.filter(
                              (LLelement) => LLelement.code === element[1],
                            ),
                        ).length > 0 ? (
                          <span className="ml-3 rounded-full bg-yellow-300 px-3 py-2">
                            <FontAwesomeIcon icon={faTriangleExclamation} />{" "}
                            Ligne perturbée
                          </span>
                        ) : (
                          <span className="ml-3 rounded-full bg-green-300 px-3 py-2">
                            <FontAwesomeIcon icon={faCircleCheck} />{" "}
                            {AorF === "A"
                              ? "Circule normalement"
                              : "Circulera normalement"}
                          </span>
                        )
                      ) : (
                        <div
                          role="status"
                          className="inline-flex max-w-sm animate-pulse"
                        >
                          <div className="mb-4 h-7 rounded-full bg-gray-200 px-10">
                            Chargement...
                          </div>
                        </div>
                      )}
                      <img
                        className="lignes m-2 inline-flex"
                        src={`https://carto-maas.infotbm.com/assets/images/lines/${element[1]}.svg`}
                        title={`Logo : ${element[2]}`}
                        alt={`Logo : ${element[2]}`}
                      />
                      <span className="ml-1 inline-flex font-bold">
                        {element[2]}
                      </span>
                      <span className="flex justify-end">
                        <span className="mr-5 flex justify-end text-sm text-gray-500">
                          Plus de détails
                          <FontAwesomeIcon
                            icon={faCircleRight}
                            className="ml-2"
                          />
                        </span>
                      </span>
                    </span>
                  </div>
                </Link>
              ))}
            </>
          )}
          <p className="mt-3">
            <FontAwesomeIcon icon={faTrain} /> Trams
          </p>
          <div id="trams" className="ml-3">
            {!feedEntities ? (
              <div role="status" className="max-w-sm animate-pulse">
                <div className="mb-4 h-2.5 rounded-full bg-gray-200"></div>
              </div>
            ) : (
              <>
                {renderRoutes((id) => id >= 59 && id <= 62)}
                {feedEntities[AorF === "A" ? "TODAY" : "FUTURE"].flatMap(
                  (Lelement) =>
                    Lelement.lines.filter((LLelement) => {
                      const id = parseInt(LLelement.id.split(":")[2]);
                      return id === 58 || (id >= 501 && id <= 507);
                    }),
                ).length === 0 && (
                  <center>
                    <div className="mt-2">
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        size="2xl"
                        style={{ color: "#63E6BE" }}
                      />
                      <br />
                      <span className="mt-1 text-lg text-gray-300">
                        Les trams circulent normalement
                      </span>
                    </div>
                  </center>
                )}
              </>
            )}
          </div>
          <p>
            <FontAwesomeIcon icon={faBusSimple} /> Bus
          </p>
          <center>
            <div id="bus">
              {!feedEntities ? (
                <div role="status" className="max-w-sm animate-pulse">
                  <div className="mb-4 h-2.5 rounded-full bg-gray-200"></div>
                </div>
              ) : (
                <>
                  {renderRoutes(
                    (id) =>
                      id === 901 ||
                      (id >= 1 && id <= 16) ||
                      (id >= 20 && id <= 57) ||
                      (id >= 64 && id !== 69 && id <= 90) ||
                      (id >= 454 && id <= 469),
                  )}
                  {feedEntities[AorF === "A" ? "TODAY" : "FUTURE"].flatMap(
                    (Lelement) =>
                      Lelement.lines.filter((LLelement) => {
                        const id = parseInt(LLelement.id.split(":")[2]);
                        return (
                          id === 901 ||
                          (id >= 1 && id <= 16) ||
                          (id >= 20 && id <= 57) ||
                          (id >= 64 && id !== 69 && id <= 90) ||
                          (id >= 454 && id <= 469)
                        );
                      }),
                  ).length === 0 && (
                    <center>
                      <div className="mt-2">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          size="2xl"
                          style={{ color: "#63E6BE" }}
                        />
                        <br />
                        <span className="mt-1 text-lg text-gray-300">
                          Les bus circulent normalement
                        </span>
                      </div>
                    </center>
                  )}
                </>
              )}
            </div>
          </center>
          <p>
            <FontAwesomeIcon icon={faFire} /> Spécial
          </p>
          <div id="autre" className="ml-3">
            {!feedEntities ? (
              <div role="status" className="max-w-sm animate-pulse">
                <div className="mb-4 h-2.5 rounded-full bg-gray-200"></div>
              </div>
            ) : (
              <>
                {renderRoutes(
                  (id) =>
                    id === 18 ||
                    id === 19 ||
                    (id >= 159 && id <= 162) ||
                    id === 568,
                )}
                {feedEntities[AorF === "A" ? "TODAY" : "FUTURE"].flatMap(
                  (Lelement) =>
                    Lelement.lines.filter((LLelement) => {
                      const id = parseInt(LLelement.id.split(":")[2]);
                      return (
                        id === 18 ||
                        id === 19 ||
                        (id >= 159 && id <= 162) ||
                        id === 568
                      );
                    }),
                ).length === 0 && (
                  <center>
                    <div className="mt-2">
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        size="2xl"
                        style={{ color: "#63E6BE" }}
                      />
                      <br />
                      <span className="mt-1 text-lg text-gray-300">
                        Les bus spéciaux circulent normalement
                      </span>
                    </div>
                  </center>
                )}
              </>
            )}
          </div>
          <p>
            <FontAwesomeIcon icon={faSchool} /> ScoDi
          </p>
          <center>
            <div id="scodi">
              {!feedEntities ? (
                <div role="status" className="max-w-sm animate-pulse">
                  <div className="mb-4 h-2.5 rounded-full bg-gray-200"></div>
                </div>
              ) : (
                <>
                  {renderRoutes((id) => id >= 801 && id <= 864)}
                  {feedEntities[AorF === "A" ? "TODAY" : "FUTURE"].flatMap(
                    (Lelement) =>
                      Lelement.lines.filter((LLelement) => {
                        const id = parseInt(LLelement.id.split(":")[2]);
                        return id >= 801 && id <= 864;
                      }),
                  ).length === 0 && (
                    <center>
                      <div className="mt-2">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          size="2xl"
                          style={{ color: "#63E6BE" }}
                        />
                        <br />
                        <span className="mt-1 text-lg text-gray-300">
                          Les ScoDis circulent normalement
                        </span>
                      </div>
                    </center>
                  )}
                </>
              )}
            </div>
          </center>
          <p>
            <FontAwesomeIcon icon={faMoon} /> Bus de Nuit
          </p>
          <center>
            <div id="nuit">
              {!feedEntities ? (
                <div role="status" className="max-w-sm animate-pulse">
                  <div className="mb-4 h-2.5 rounded-full bg-gray-200"></div>
                </div>
              ) : (
                <>
                  {renderRoutes((id) => id === 58 || (id >= 501 && id <= 507))}
                  {feedEntities[AorF === "A" ? "TODAY" : "FUTURE"].flatMap(
                    (Lelement) =>
                      Lelement.lines.filter((LLelement) => {
                        const id = parseInt(LLelement.id.split(":")[2]);
                        return id === 58 || (id >= 501 && id <= 507);
                      }),
                  ).length === 0 && (
                    <center>
                      <div className="mt-2">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          size="2xl"
                          style={{ color: "#63E6BE" }}
                        />
                        <br />
                        <span className="mt-1 text-lg text-gray-300">
                          Les bus de nuit circulent normalement
                        </span>
                      </div>
                    </center>
                  )}
                </>
              )}
            </div>
          </center>
          <p>
            <FontAwesomeIcon icon={faShip} /> BAT3
          </p>
          <div id="bateau" className="ml-3">
            {!feedEntities ? (
              <div role="status" className="max-w-sm animate-pulse">
                <div className="mb-4 h-2.5 rounded-full bg-gray-200"></div>
              </div>
            ) : (
              <>
                {renderRoutes((id) => id === 69)}
                {feedEntities[AorF === "A" ? "TODAY" : "FUTURE"].flatMap(
                  (Lelement) =>
                    Lelement.lines.filter((LLelement) => {
                      const id = parseInt(LLelement.id.split(":")[2]);
                      return id === 69;
                    }),
                ).length === 0 && (
                  <center>
                    <div className="mt-2">
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        size="2xl"
                        style={{ color: "#63E6BE" }}
                      />
                      <br />
                      <span className="mt-1 text-lg text-gray-300">
                        Le BAT3 circule normalement
                      </span>
                    </div>
                  </center>
                )}
              </>
            )}
          </div>
        </>
      ) : ligneChoise && !InfoChoise ? (
        !feedLigne ? (
          <div role="status" className="max-w-sm animate-pulse">
            <div className="my-4 h-2.5 rounded-full bg-gray-200"></div>
          </div>
        ) : (
          <>
            {feedLigne[AorF === "A" ? "TODAY" : "FUTURE"].length === 0 ? (
              <center>
                <div className="mt-5 w-1/2 rounded-xl bg-white py-4">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    size="2xl"
                    style={{ color: "#63E6BE" }}
                  />
                  <br />
                  <span className="mt-1 text-lg text-gray-500">
                    La ligne choisie{" "}
                    {AorF === "A"
                      ? "circule normalement"
                      : "n'a pas de perturbations à venir"}
                  </span>
                </div>
              </center>
            ) : (
              feedLigne[AorF === "A" ? "TODAY" : "FUTURE"].map((element) => (
                <div
                  className="mt-3 rounded-3xl bg-white py-5"
                  key={element.id}
                >
                  <Link
                    to={`/InfoTrafic?ligne=${ligneChoise}&info=${element.id}`}
                  >
                    <center>
                      {element.severity === "DISTURBED" ? (
                        <span className="ml-3 rounded-full bg-yellow-300 px-3 py-2">
                          <FontAwesomeIcon icon={faTriangleExclamation} />{" "}
                          Perturbation
                        </span>
                      ) : element.severity === "INFORMATIVE" ? (
                        <span className="ml-3 rounded-full bg-teal-200 px-3 py-2">
                          <FontAwesomeIcon icon={faCircleInfo} /> Information
                        </span>
                      ) : (
                        <span className="ml-3 rounded-full bg-gray-300 px-3 py-2">
                          <FontAwesomeIcon icon={faCircleQuestion} /> Inconnu
                        </span>
                      )}
                    </center>
                    <p className="mx-5 mt-2 font-bold">{element.title}</p>
                    <p className="ml-5 italic">
                      {AorF === "A"
                        ? element.endDate &&
                          `Se termine le ${new Date(element.endDate).getDate().toString().padStart(2, "0")}/${(new Date(element.endDate).getMonth() + 1).toString().padStart(2, "0")}/${new Date(element.endDate).getFullYear()}`
                        : `Commence le ${new Date(element.startDate).getDate().toString().padStart(2, "0")}/${(new Date(element.startDate).getMonth() + 1).toString().padStart(2, "0")}/${new Date(element.startDate).getFullYear()}`}
                    </p>
                    <span className="mr-5 flex justify-end text-sm text-gray-500">
                      Plus de détails
                      <FontAwesomeIcon icon={faCircleRight} className="ml-2" />
                    </span>
                  </Link>
                </div>
              ))
            )}
          </>
        )
      ) : !feedId ? (
        <div role="status" className="max-w-sm animate-pulse">
          <div className="my-4 h-2.5 rounded-full bg-gray-200"></div>
        </div>
      ) : (
        <>
          <center>
            <img
              className="lignes m-2 inline-flex"
              src={`https://carto-maas.infotbm.com/assets/images/lines/${feedId.line.code}.svg`}
              title={`Logo : ${feedId.line.name}`}
              alt={`Logo : ${feedId.line.name}`}
            />
            <br />
            <b className="text-xl">{feedId.line.name}</b>
          </center>
          {feedId.eventUpdates && (
            <div
              id="majInfo"
              className="mt-3 rounded-3xl bg-gray-300 py-5 pl-5"
              dangerouslySetInnerHTML={{ __html: feedId.eventUpdates.split("<br/>").toString() }}
            ></div>
          )}
          {feedId.content ? (
            <>
              <center>
                <h3
                  className="mt-3 text-3xl font-extrabold"
                  dangerouslySetInnerHTML={{ __html: feedId.description }}
                ></h3>
              </center>
              <p
                className="mt-1"
                dangerouslySetInnerHTML={{
                  __html: feedId.content,
                }}
              ></p>
            </>
          ) : (
            <p
              className="mt-3"
              dangerouslySetInnerHTML={{ __html: feedId.description }}
            ></p>
          )}
          {feedId.imageUrl && (
            <>
              <br />
              <i className="mt-5">Pièce jointe</i>
              <Link
                to={feedId.imageUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="m-2 inline-flex w-1/2"
                  src={feedId.imageUrl}
                  title={`Logo : ${feedId.line.name}`}
                  alt={`Logo : ${feedId.line.name}`}
                />
              </Link>
            </>
          )}
        </>
      )}
    </main>
  );
};

export default LAPage;
