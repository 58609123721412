import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  DialogBackdrop,
  DialogTitle,
} from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faKeyboard,
  faFlag,
  IconDefinition,
  faClockRotateLeft,
  faWifi,
  faStar,
  faXmark,
  faCircleRight,
  faBug,
  faEye,
  faEyeSlash,
  faBusSimple,
  faFire,
  faMoon,
  faSchool,
  faShip,
  faTrain,
  faChevronLeft,
  faMagnifyingGlass,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import protobuf, { Root } from "protobufjs";
import { readFile, saveFile } from "./deps/OPFS";
import { routesRequete, stopsRequete } from "./deps/DoNotOPFS";

type TypeListeArrets = {
  code: string;
  documents: [
    {
      endDate: string;
      startDatee: string;
      title: string;
      type: string;
      url: string;
    },
  ];
  iconUrl: string;
  id: string;
  isOperating: boolean;
  isSpecial: boolean;
  mapUrl: string;
  mode: string;
  name: string;
  routes: {
    id: string;
    name: string;
    stopPoints: [
      {
        coordinates: {
          latitude: number;
          longitude: number;
        };
        hasWheelchairBoarding: boolean;
        id: string;
        isPartialTerminus: boolean;
        name: string;
        stopAreaId: string;
      },
    ];
  }[];
  style: {
    color: string;
    textColor: string;
  };
  timetableUrl: string;
};

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

type typeGTFS = {
  routes: {
    id: string;
    name: string;
    name_short: string;
    terminus: { direction: string | string[]; id: string }[];
  }[];
  stops: {
    id: string[];
    lignes: string[];
    name: string;
    position: number[];
  }[];
};

const LAPage = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const OPFSString = localStorage.getItem("OPFS");
  // eslint-disable-next-line
  const OPFS: {
    routes?: string;
    shapes?: string;
    stops?: string;
  } = OPFSString ? JSON.parse(OPFSString) : {};
  const [maj, setMaj] = useState<true | false>(false);
  const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000; // 172800000 ms
  const [GTFS, setGTFS] = useState<typeGTFS | null>(null);
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [favoris, setFavoris] = useState<[boolean, string[][]]>([false, []]);
  const [item, setItem] = useState<string[][]>([]);
  const [itemFavoris, setItemsFavoris] = useState<
    [string, string, string, string, string][]
  >([]);
  const [listeArrets, setListeArrets] = useState<TypeListeArrets | null>(null);
  const [groupedTrips, setGroupedTrips] = useState<{
    [routeId: string]: {
      [directionId: string]: {
        time: string;
        status: string;
        color: string;
        isRealTime: boolean;
        LAdirection: string | undefined;
      }[];
    };
  } | null>(null);
  const location = useLocation();
  const [url, setUrl] = useState(location.search);
  const arret = new URLSearchParams(location.search).get("arret");
  const ligne = new URLSearchParams(location.search).get("ligne");
  const LAdirection = new URLSearchParams(location.search).get("direction");
  const [direction, setDirection] = useState<number>(0);
  const [selectedFavorites, setSelectedFavorites] = useState<string[]>(
    arret
      ? JSON.parse(localStorage.getItem("ArretsFavoris") || "{}")[arret] || []
      : [],
  );
  const [erreur, setErreur] = useState<[boolean, null | any]>([false, null]);
  const [view, setView] = useState<boolean>(false);

  useEffect(() => {
    setSelectedFavorites(
      arret
        ? JSON.parse(localStorage.getItem("ArretsFavoris") || "{}")[arret] || []
        : [],
    );
  }, [arret]);

  const handleCheckboxChange = (
    line: [string, string, string, string, string],
  ) => {
    const lineId = JSON.stringify([
      [line[1], line[0], line[4]],
      [line[2], line[3]],
    ]); // Concatenate line_short and direction for unique identification

    setSelectedFavorites((prev) =>
      prev.includes(lineId)
        ? prev.filter((id) => id !== lineId)
        : [...prev, lineId],
    );
  };

  const handleSaveFavorites = () => {
    const existingFavorites = JSON.parse(
      localStorage.getItem("ArretsFavoris") || "{}",
    );
    if (arret) {
      if (selectedFavorites) {
        existingFavorites[arret] = selectedFavorites;
      } else {
        delete existingFavorites[arret];
      }
      localStorage.setItem("ArretsFavoris", JSON.stringify(existingFavorites));
    }
    window.location.reload();
  };

  const requetes = useCallback(async () => {
    let routes: {
      id: string;
      name: string;
      name_short: string;
      terminus: {
        direction: string | string[];
        id: string;
      }[];
    }[] = [];
    let stops: {
      id: string[];
      lignes: string[];
      name: string;
      position: number[];
    }[] = [];

    try {
      if (!isSafari && navigator.storage && Object.keys(OPFS).length !== 0) {
        const mtn = Date.now().toString();
        // routes.json
        if (
          OPFS.routes &&
          Date.now() - parseInt(OPFS.routes, 10) < twoDaysInMilliseconds
        ) {
          routes = await readFile("routes.json");
        } else {
          setMaj(true);
          const requete1 = await routesRequete();
          if (!requete1) {
            setErreur([true, "TypeError: Failed to fetch"]);
          } else {
            await saveFile("routes.json", requete1);
            OPFS.routes = mtn;
            localStorage.setItem("OPFS", JSON.stringify(OPFS));
            routes = requete1;
          }
        }

        // stops.json
        if (
          OPFS.stops &&
          Date.now() - parseInt(OPFS.stops, 10) < twoDaysInMilliseconds
        ) {
          stops = await readFile("stops.json");
        } else {
          setMaj(true);
          const requete2 = await stopsRequete();
          if (!requete2) {
            setErreur([true, "TypeError: Failed to fetch"]);
          } else {
            await saveFile("stops.json", requete2);
            OPFS.stops = mtn;
            localStorage.setItem("OPFS", JSON.stringify(OPFS));
            stops = requete2;
          }
        }

        if (maj) {
          window.location.reload();
        }
      } else {
        const requete1 = await stopsRequete();
        const requete2 = await routesRequete();

        if (!requete1) {
          setErreur([true, "TypeError: Failed to fetch"]);
        } else {
          stops = requete1;
        }

        if (!requete2) {
          setErreur([true, "TypeError: Failed to fetch"]);
        } else {
          routes = requete2;
        }
      }
    } catch (ERRfatal) {
      console.error(ERRfatal);
      setErreur([true, ERRfatal]);
    }

    routes.sort((a, b) => {
      const aShort = parseInt(a.name_short);
      const bShort = parseInt(b.name_short);

      if (!Number.isNaN(aShort) && !Number.isNaN(bShort)) {
        return aShort - bShort; // Tri numérique
      } else {
        return a.id.localeCompare(b.id); // Tri lexicographique pour les IDs
      }
    });

    setGTFS({
      stops: stops,
      routes: routes,
    });
  }, [isSafari, setGTFS, OPFS, maj, twoDaysInMilliseconds]);

  const fetchdata = useCallback(async () => {
    // GTFS-RT TripUpdate
    try {
      const response = await fetch(
        "https://bdx.mecatran.com/utw/ws/gtfsfeed/realtime/bordeaux?apiKey=opendata-bordeaux-metropole-flux-gtfs-rt",
      );
      const data = await response.arrayBuffer();
      const root: Root = await new Promise((resolve, reject) => {
        protobuf.load(
          "./ressources/GTFS/fichier.proto",
          (ERRfatal: any, root: any) => {
            if (ERRfatal) {
              reject(ERRfatal);
            } else {
              resolve(root);
            }
          },
        );
      });
      const FeedMessage = root.lookupType("transit_realtime.FeedMessage");
      const message = FeedMessage.decode(new Uint8Array(data));
      const tripUpdates: {
        id: string;
        tripUpdate: {
          stopTimeUpdate: {
            arrival?: {
              delay: number;
              time: string;
            };
            departure?: {
              delay: number;
              time: string;
            };
            scheduleRelationship: string;
            stopId: string;
            stopSequence: number;
          }[];
          timestamp: string;
          trip: {
            directionId: number;
            routeId: string;
            scheduleRelationship: string;
            startDate: string;
            tripId: string;
          };
          vehicle?: {
            id: string;
            label: string;
          };
        };
      }[] = JSON.parse(JSON.stringify(message, null, 2)).entity;

      // Filtrer les prochains passages par arrêt
      const stop = GTFS?.stops.find((stop) => stop.name === arret);

      let relevantTrips;
      let stopIds: string | string[];
      if (stop) {
        stopIds = Array.isArray(stop.id) ? stop.id : [stop.id];

        if (ligne && LAdirection) {
          relevantTrips = tripUpdates.filter(
            (tripUpdate) =>
              tripUpdate.tripUpdate.trip.directionId ===
                parseInt(LAdirection) &&
              tripUpdate.tripUpdate.trip.routeId === ligne &&
              tripUpdate.tripUpdate.stopTimeUpdate.some((stopTimeUpdate) =>
                stopIds.includes(stopTimeUpdate.stopId),
              ),
          );
        } else {
          relevantTrips = tripUpdates.filter((tripUpdate) =>
            tripUpdate.tripUpdate.stopTimeUpdate.some((stopTimeUpdate) =>
              stopIds.includes(stopTimeUpdate.stopId),
            ),
          );
        }
      }

      // Grouper les passages par ligne et terminus
      const groupedTrips: {
        [routeId: string]: {
          [directionId: string]: {
            time: string;
            status: string;
            color: string;
            isRealTime: boolean;
            LAdirection: string | undefined;
          }[];
        };
      } = {};

      relevantTrips?.forEach((tripUpdate) => {
        const { routeId, directionId } = tripUpdate.tripUpdate.trip;
        const isRealTime = !!tripUpdate.tripUpdate.vehicle;
        const stopTimes = tripUpdate.tripUpdate.stopTimeUpdate.filter(
          (stopTimeUpdate) => stopIds.includes(stopTimeUpdate.stopId),
        );

        if (!groupedTrips[routeId]) {
          groupedTrips[routeId] = {};
        }
        if (!groupedTrips[routeId][directionId]) {
          groupedTrips[routeId][directionId] = [];
        }

        // Logique d'annulation du véhicule
        const isVehicleCanceled = tripUpdate.tripUpdate.stopTimeUpdate.every(
          (update) => update.scheduleRelationship === "SKIPPED",
        );

        if (isVehicleCanceled) {
          if (
            groupedTrips[routeId][directionId].filter((element) =>
              element.status.includes("🚫"),
            ).length === 1
          ) {
            groupedTrips[routeId][directionId].forEach((element) => {
              if (element.status.includes("🚫")) {
                element.status = `🚫 <b>${parseInt(element.status[6]) + 1} Véhicules annulés</b>`;
                return;
              }
            });
          } else {
            groupedTrips[routeId][directionId].push({
              time: "Annulé",
              status: "🚫 <b>1 Véhicule annulé</b>",
              color: "red",
              isRealTime: false,
              LAdirection: undefined,
            });
          }
          return;
        }

        stopTimes.forEach((stopTimeUpdate) => {
          let status = "🟢 À l'heure";
          let color = "green";
          let timeDifference = 0;
          let exactTime = "";
          let delayInMinutes = 0;

          // Vérifier si l'arrêt est desservi avant d'être marqué comme non desservi
          const hasServedStop = tripUpdate.tripUpdate.stopTimeUpdate.some(
            (update) => update.scheduleRelationship === "SCHEDULED",
          );

          if (stopTimeUpdate.scheduleRelationship === "SKIPPED") {
            if (!groupedTrips[routeId][directionId][0] && !hasServedStop) {
              groupedTrips[routeId][directionId].push({
                time: "N/A",
                status:
                  "⚠️ <b>Arrêt non desservi</b>. Plus d'informations dans l'Info trafic 😉",
                color: "orange",
                isRealTime: false,
                LAdirection: undefined,
              });
            } else if (hasServedStop) {
              if (
                groupedTrips[routeId][directionId].filter((element) =>
                  element.status.includes("🚫"),
                ).length === 1
              ) {
                groupedTrips[routeId][directionId].forEach((element) => {
                  if (element.status.includes("🚫")) {
                    element.status = `🚫 <b>${parseInt(element.status[6]) + 1} Véhicules annulés</b>`;
                    return;
                  }
                });
              } else {
                groupedTrips[routeId][directionId].push({
                  time: "Annulé",
                  status: "🚫 <b>1 Véhicule annulé</b>",
                  color: "red",
                  isRealTime: false,
                  LAdirection: undefined,
                });
              }
            }
            return;
          }

          // Calculer l'heure d'arrivée ou de départ
          if (stopTimeUpdate.arrival && stopTimeUpdate.arrival.time) {
            const arrivalTime = new Date(
              parseInt(stopTimeUpdate.arrival.time) * 1000,
            );
            timeDifference = Math.floor(
              (arrivalTime.getTime() - new Date().getTime()) / 60000,
            );
            exactTime = arrivalTime.toLocaleTimeString("fr-FR", {
              hour: "2-digit",
              minute: "2-digit",
            });
            delayInMinutes = Math.floor(
              (stopTimeUpdate.arrival.delay || 0) / 60,
            );
          } else if (
            stopTimeUpdate.departure &&
            stopTimeUpdate.departure.time
          ) {
            const departureTime = new Date(
              parseInt(stopTimeUpdate.departure.time) * 1000,
            );
            timeDifference = Math.floor(
              (departureTime.getTime() - new Date().getTime()) / 60000,
            );
            exactTime = departureTime.toLocaleTimeString("fr-FR", {
              hour: "2-digit",
              minute: "2-digit",
            });
            delayInMinutes = Math.floor(
              (stopTimeUpdate.departure.delay || 0) / 60,
            );
          }

          // Déterminer le statut en fonction du délai
          if (delayInMinutes > -1 && delayInMinutes < 1) {
            status = "🟢 À l'heure";
            color = "green";
          } else if (delayInMinutes >= 1) {
            status = `⚠️ En <b><u>retard</u></b> de ${delayInMinutes} min`;
            color = "orange";
          } else if (delayInMinutes <= -1) {
            status = `⚠️ En <b><u>avance</u></b> de ${Math.abs(delayInMinutes)} min`;
            color = "red";
          }

          const LAdirection = tripUpdate.tripUpdate.vehicle?.label;

          if (timeDifference > 0) {
            groupedTrips[routeId][directionId].push({
              time: timeDifference > 59 ? exactTime : `${timeDifference} min`,
              status,
              color,
              isRealTime,
              LAdirection,
            });
          }
        });

        // Supprimer le groupe si aucun stopTimeUpdate valide n'existe
        if (groupedTrips[routeId][directionId].length === 0) {
          delete groupedTrips[routeId][directionId];
        } else {
          // Limitez la liste à 5 éléments
          groupedTrips[routeId][directionId] = groupedTrips[routeId][
            directionId
          ].slice(0, 5);
        }
      });

      // Améliorer le tri des passages
      Object.keys(groupedTrips).forEach((routeId) => {
        Object.keys(groupedTrips[routeId]).forEach((directionId) => {
          groupedTrips[routeId][directionId].sort((a, b) => {
            const timeA = a.time.includes(":")
              ? parseInt(
                  (a.time.split(":")[0] + a.time.split(":")[1]).replace(
                    "00",
                    "100",
                  ),
                )
              : a.time === "Annulé"
                ? -9999
                : parseInt(a.time.split(" min")[0].padStart(2, "0"));
            const timeB = b.time.includes(":")
              ? parseInt(
                  (b.time.split(":")[0] + b.time.split(":")[1]).replace(
                    "00",
                    "100",
                  ),
                )
              : b.time === "Annulé"
                ? -9999
                : parseInt(b.time.split(" min")[0].padStart(2, "0"));
            return timeA - timeB;
          });
        });
      });

      const item: string[][] = [];
      const ids: string[] = [];
      const itemFavoris: [string, string, string, string, string][] = [];
      GTFS?.stops
        .filter((element) => element.name === arret)
        .map((element) =>
          element.lignes.forEach((Lelement) => {
            if (!ids.includes(Lelement)) {
              const filtre = GTFS?.routes.filter(
                (LLelement) => Lelement === LLelement.id,
              )[0];
              item.push([filtre.name, filtre.name_short]);
              ids.push(filtre.id);
              itemFavoris.push([
                filtre.name,
                filtre.name_short,
                GTFS?.routes.filter((element) => element.id === filtre.id)[0]
                  .terminus[0].id,
                GTFS.routes
                  .filter((element) => element.id === filtre.id)[0]
                  .terminus[0].direction.toString()
                  .replaceAll(",", " / "),
                Lelement,
              ]);
              if (
                GTFS?.routes.filter((element) => element.id === filtre.id)[0]
                  .terminus.length > 1
              ) {
                itemFavoris.push([
                  filtre.name,
                  filtre.name_short,
                  GTFS?.routes.filter((element) => element.id === filtre.id)[0]
                    .terminus[1].id,
                  GTFS?.routes
                    .filter((element) => element.id === filtre.id)[0]
                    .terminus[1].direction.toString()
                    .replaceAll(",", " / "),
                  Lelement,
                ]);
              }
            }
          }),
        );

      setGroupedTrips(groupedTrips);
      setItemsFavoris(itemFavoris);
      setItem(item);
    } catch (ERRfatal) {
      console.error(ERRfatal);
      setErreur([true, ERRfatal]);
    }
  }, [
    GTFS,
    ligne,
    LAdirection,
    arret,
    setGroupedTrips,
    setItemsFavoris,
    setItem,
  ]);

  const arretsLigne = useCallback(async () => {
    try {
      const response = await fetch(
        `https://gateway-apim.infotbm.com/maas-web/web/v1/timetables/lines/line:BMA:${ligne}`,
      );
      const data = await response.json();
      setListeArrets(data);
    } catch (ERRfatal) {
      console.error(ERRfatal);
      setErreur([true, ERRfatal]);
    }
  }, [ligne, setListeArrets]);

  useEffect(() => {
    if (GTFS && location.search === url && arret) {
      if (!groupedTrips) {
        fetchdata();
      } else {
        const intervalId = setInterval(fetchdata, 30000); // Set up interval to fetch data every 30 seconds
        return () => clearInterval(intervalId); // Cleanup on unmount
      }
    } else {
      if (!GTFS) {
        requetes();
      }
      if (arret) {
        setGroupedTrips(null);
        setUrl(location.search);
      } else if (ligne) {
        arretsLigne();
      }
    }
  }, [
    location,
    url,
    groupedTrips,
    fetchdata,
    GTFS,
    requetes,
    arret,
    setGroupedTrips,
    setUrl,
    ligne,
    arretsLigne,
  ]);

  const renderRoutes = (filterCondition: (id: number) => boolean) => {
    if (GTFS !== null) {
      return GTFS.routes
        .filter((element) => filterCondition(parseInt(element.id)))
        .map((element) => {
          return (
            <Link
              key={element.id}
              to={`/ProchainsPassages?ligne=${element.id}`}
            >
              <img
                className="lignes m-2 inline-flex"
                src={`https://carto-maas.infotbm.com/assets/images/lines/${element.name_short}.svg`}
                title={`Logo : ${element.name}`}
                alt={`Logo : ${element.name}`}
              />
            </Link>
          );
        });
    }
  };

  if (!GTFS) {
    return (
      <div className="text-center">
        <div role="status">
          <svg
            aria-hidden="true"
            className="inline h-8 w-8 animate-spin fill-yellow-400 text-gray-200"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  } else if (!arret) {
    if (ligne) {
      const element = GTFS.routes.filter(
        (LLelement) => ligne === LLelement.id,
      )[0];

      return (
        <main>
          <Link to="/ProchainsPassages">
            <button
              type="button"
              className="mb-2 me-2 rounded-full bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
              title="Choisir une autre ligne"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          </Link>
          <center>
            <img
              className="m-2"
              src={`https://carto-maas.infotbm.com/assets/images/lines/${element.name_short}.svg`}
              title={`Logo : ${element.name}`}
              alt={`Logo : ${element.name}`}
            />
            <h2 className="mt-2 text-4xl font-bold">{element.name}</h2>

            {!listeArrets ? (
              <div role="status" className="max-w-sm animate-pulse">
                <div className="mb-4 h-2.5 rounded-full bg-gray-200"></div>
              </div>
            ) : (
              <>
                <center>
                  <h4 className="mt-3 text-lg font-bold">Direction :</h4>
                </center>
                {listeArrets.routes.length === 2 && (
                  <div className="z-50 w-full">
                    <div className="w-full">
                      <div
                        className="mx-auto my-2 grid max-w-sm grid-cols-2 gap-1 rounded-lg bg-gray-100 p-1"
                        role="group"
                      >
                        <button
                          type="button"
                          className={
                            direction === 0
                              ? "rounded-lg bg-teal-400 px-2 py-1.5 text-xs font-medium text-white"
                              : "rounded-lg px-2 py-1.5 text-xs font-medium hover:bg-gray-200"
                          }
                          onClick={() => direction === 1 && setDirection(0)}
                        >
                          {listeArrets.routes[0].name}
                        </button>
                        <button
                          type="button"
                          className={
                            direction === 1
                              ? "rounded-lg bg-teal-400 px-2 py-1.5 text-xs font-medium text-white"
                              : "rounded-lg px-2 py-1.5 text-xs font-medium hover:bg-gray-200"
                          }
                          onClick={() => direction === 0 && setDirection(1)}
                        >
                          {listeArrets.routes[1].name}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </center>
          <ul className="w-1/2 rounded-lg border border-gray-200 bg-white text-sm font-medium text-gray-900">
            {listeArrets &&
              listeArrets.routes[direction].stopPoints.map((element) => {
                const nameArret =
                  element.name.split(" (").length === 3
                    ? element.name.split(" (")[0] +
                      " (" +
                      element.name.split(" (")[1]
                    : element.name.split(" (")[0];

                return (
                  <Link
                    key={`${nameArret}|${element.id}`}
                    to={`/ProchainsPassages?arret=${nameArret.replace("&", "%26")}&ligne=${ligne}&direction=${direction}`}
                  >
                    <li className="ml-5 border-b border-gray-200 px-4 py-2 text-lg font-bold">
                      {nameArret}
                      <span className="flex justify-end text-sm text-gray-400">
                        Consulter{" "}
                        <FontAwesomeIcon
                          icon={faUpRightFromSquare}
                          className="ml-2"
                        />
                      </span>
                    </li>
                  </Link>
                );
              })}
          </ul>
        </main>
      );
    } else {
      if (open) {
        const items: {
          id: string[];
          name: string;
          desserte: string[][];
          url: string;
          color: string;
          icon: IconDefinition;
        }[] = [];
        const ids: string[] = [];

        GTFS.stops.forEach((element) => {
          if (!ids.includes(element.name)) {
            const ajoutItems = {
              id: element.id,
              name: element.name,
              desserte: element.lignes.map((Lelement) => {
                const filtre = GTFS.routes.filter(
                  (LLelement) => Lelement === LLelement.id,
                )[0];
                return [filtre.name, filtre.name_short];
              }),

              url: `ProchainsPassages?arret=${element.name.replace("&", "%26")}`,
              color: "bg-indigo-500",
              icon: faFlag,
            };
            items.push(ajoutItems);
            ids.push(element.name);
          }
        });

        const filteredItems =
          query === ""
            ? []
            : items.filter((item) => {
                return item.name.toLowerCase().includes(query.toLowerCase());
              });

        return (
          <Dialog
            className="relative z-10"
            open={open}
            onClose={() => {
              setOpen(false);
              setQuery("");
            }}
          >
            <DialogBackdrop
              transition
              className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
              <DialogPanel
                transition
                className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <Combobox>
                  <div className="relative">
                    <FontAwesomeIcon
                      icon={faKeyboard}
                      className="pointer-events-none absolute left-4 top-3.5 h-5 w-5"
                    />
                    <ComboboxInput
                      autoFocus
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="Écrire le nom d'un arrêt"
                      onChange={(event) => {
                        if (event.target.value.length >= 2) {
                          setQuery(event.target.value);
                        } else {
                          setQuery("");
                        }
                      }}
                      onBlur={() => setQuery("")}
                    />
                  </div>

                  {filteredItems.length > 0 && (
                    <ComboboxOptions
                      static
                      className="max-h-96 transform-gpu scroll-py-3 overflow-y-auto p-3"
                    >
                      {filteredItems.map((item) => (
                        <Link
                          key={
                            typeof item.id === "string" ? item.id : item.id[0]
                          }
                          to={"/" + item.url}
                          onClick={() => setOpen(false)}
                        >
                          <ComboboxOption
                            value={item}
                            className="group flex cursor-default select-none rounded-xl p-3 data-[focus]:bg-gray-100"
                          >
                            <div
                              className={classNames(
                                "flex h-10 w-10 flex-none items-center justify-center rounded-lg",
                                item.color,
                              )}
                            >
                              <FontAwesomeIcon icon={item.icon} />
                            </div>
                            <div className="ml-4 flex-auto">
                              <p className="text-center text-sm font-medium text-gray-700 group-data-[focus]:text-gray-900">
                                {item.name}
                              </p>
                              <p className="text-sm text-gray-500 group-data-[focus]:text-gray-700">
                                {item.desserte.map((element) => (
                                  <img
                                    key={element[0]}
                                    className="lignes m-2 inline-flex"
                                    src={`https://carto-maas.infotbm.com/assets/images/lines/${element[1]}.svg`}
                                    alt={`Logo : ${element[0]}`}
                                    title={`Logo : ${element[0]}`}
                                  />
                                ))}
                              </p>
                            </div>
                          </ComboboxOption>
                        </Link>
                      ))}
                    </ComboboxOptions>
                  )}

                  {query !== "" && filteredItems.length === 0 && (
                    <div className="px-6 py-14 text-center text-sm sm:px-14">
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        className="mx-auto h-6 w-6 text-red-400"
                      />
                      <p className="mt-4 font-semibold text-gray-900">
                        Aucun résultat
                      </p>
                      <p className="mt-2 text-gray-500">
                        L'arrêt écrit n'a pas été trouvé
                      </p>
                    </div>
                  )}
                </Combobox>
              </DialogPanel>
            </div>
          </Dialog>
        );
      }

      return (
        <main>
          {erreur[0] && (
            <Dialog open={true} onClose={() => true} className="relative z-10">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />

              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-300 sm:mx-0 sm:h-10 sm:w-10">
                          <FontAwesomeIcon icon={faBug} />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            Erreur
                          </DialogTitle>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              {" "}
                              <b>
                                {String(erreur[1]).includes("fetch")
                                  ? "lors d'une requête"
                                  : "inconnue"}
                              </b>{" "}
                              est survenue.{" "}
                              {String(erreur[1]).includes("fetch")
                                ? "Vérifie ta connexion Internet et actualise la page"
                                : "Patiente quelques instants puis actualise la page"}
                              <br />
                              <span
                                className="flex justify-start text-blue-500"
                                onClick={() => setView(!view)}
                              >
                                {view ? (
                                  <FontAwesomeIcon
                                    icon={faEyeSlash}
                                    className="mr-1"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faEye}
                                    className="mr-1"
                                  />
                                )}
                                {view
                                  ? "Cacher les détails"
                                  : "Afficher les détails"}
                              </span>
                              {view && (
                                <span className="flex justify-start text-sm italic text-gray-500">
                                  {String(erreur[1])}
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <Link
                        to="/ProchainsPassages"
                        onClick={() => window.location.reload()}
                      >
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-300 sm:ml-3 sm:w-auto"
                        >
                          Actualiser la page
                        </button>
                      </Link>
                      <Link to="/Accueil">
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        >
                          Retour à l'accueil
                        </button>
                      </Link>
                    </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>
          )}
          <form
            className="mx-auto flex max-w-sm items-center"
            onClick={() => {
              setOpen(true);
              setQuery("");
            }}
          >
            <div className="relative w-full">
              <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </div>
              <button
                type="button"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 ps-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
              >
                Chercher un arrêt...
              </button>
            </div>
          </form>
          <p className="my-6">Ou choisis une ligne :</p>
          <h5>
            <FontAwesomeIcon icon={faTrain} /> Trams
          </h5>
          <div id="trams" className="ml-3">
            {GTFS && renderRoutes((id) => id >= 59 && id <= 62)}
          </div>
          <h5>
            <FontAwesomeIcon icon={faBusSimple} /> Bus
          </h5>
          <center>
            <div id="bus">
              {GTFS &&
                renderRoutes(
                  (id) =>
                    id === 901 ||
                    (id >= 1 && id <= 16) ||
                    (id >= 20 && id <= 57) ||
                    (id >= 64 && id !== 69 && id <= 90) ||
                    (id >= 454 && id <= 469),
                )}
            </div>
          </center>
          <h5>
            <FontAwesomeIcon icon={faFire} /> Spécial
          </h5>
          <div id="autre" className="ml-3">
            {GTFS &&
              renderRoutes(
                (id) =>
                  id === 18 ||
                  id === 19 ||
                  (id >= 159 && id <= 162) ||
                  id === 568 ||
                  id === 9999,
              )}
          </div>
          <h5>
            <FontAwesomeIcon icon={faSchool} /> ScoDi
          </h5>
          <center>
            <div id="scodi">
              {GTFS && renderRoutes((id) => id >= 801 && id <= 864)}
            </div>
          </center>
          <h5>
            <FontAwesomeIcon icon={faMoon} /> Bus de Nuit
          </h5>
          <center>
            <div id="nuit">
              {GTFS &&
                renderRoutes((id) => id === 58 || (id >= 501 && id <= 507))}
            </div>
          </center>
          <h5>
            <FontAwesomeIcon icon={faShip} /> BAT3
          </h5>
          <div id="bateau" className="ml-3">
            {GTFS && renderRoutes((id) => id === 69)}
          </div>
        </main>
      );
    }
  }

  if (favoris[0]) {
    return (
      <Dialog
        open={favoris[0]}
        onClose={() => setFavoris([false, []])}
        className="relative z-10"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-200 sm:mx-0 sm:h-10 sm:w-10">
                    <FontAwesomeIcon icon={faStar} />
                  </div>
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setFavoris([false, []])}
                    >
                      <span className="sr-only">Close</span>
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Quelle(s) ligne(s) veux-tu ajouter dans les arrêts favoris
                      ?
                    </DialogTitle>
                    <ul className="mt-3 w-full rounded-lg border border-gray-200 bg-white text-sm font-medium text-gray-900">
                      {itemFavoris.map((favori) => (
                        <li
                          key={`${favori[1]}|${favori[2]}`}
                          className="flex items-center ps-3"
                        >
                          <input
                            id={`${favori[1]}|${favori[0]}|${favori[2]}|${favori[3]}|${favori[4]}-checkbox`}
                            type="checkbox"
                            value=""
                            className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500"
                            onChange={() => handleCheckboxChange(favori)}
                            checked={
                              selectedFavorites &&
                              selectedFavorites.includes(
                                JSON.stringify([
                                  [favori[1], favori[0], favori[4]],
                                  [favori[2], favori[3]],
                                ]),
                              )
                            }
                          />
                          <label
                            htmlFor={`${favori[1]}|${favori[2]}-checkbox`}
                            className="ms-2 py-3 text-sm font-medium text-gray-900"
                          >
                            <img
                              className="lignes m-2 inline-flex"
                              src={`https://carto-maas.infotbm.com/assets/images/lines/${favori[1]}.svg`}
                              alt={`Logo : ${favori[0]}`}
                              title={`Logo : ${favori[0]}`}
                            />{" "}
                            <FontAwesomeIcon
                              icon={faCircleRight}
                              fill="#74C0FC"
                            />{" "}
                            {favori[3]}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleSaveFavorites}
                >
                  Sauvegarder
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setFavoris([false, []])}
                >
                  Annuler
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    );
  }

  return (
    <div className="p-4">
      <Link to="/ProchainsPassages">
        <button
          type="button"
          className="mb-3 me-2 ml-2 rounded-full bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
          title="Choisir un autre arrêt"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </Link>
      <br />
      <center>
        <h1 className="mb-4 text-xl font-semibold">
          <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-indigo-500">
            <FontAwesomeIcon icon={faFlag} />
          </div>
          <div className="">{arret}</div>
          <p className="text-sm text-gray-500 group-data-[focus]:text-gray-700">
            {item.map((element) => (
              <img
                key={element[1]}
                className="lignes m-2 inline-flex"
                src={`https://carto-maas.infotbm.com/assets/images/lines/${element[1]}.svg`}
                alt={`Logo : ${element[0]}`}
                title={`Logo : ${element[0]}`}
              />
            ))}
          </p>
          {!ligne && !LAdirection ? (
            <button
              type="button"
              className="mb-2 me-2 rounded-lg bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 px-5 py-2.5 text-center text-sm font-medium text-gray-900 hover:bg-gradient-to-br focus:outline-none focus:ring-4 focus:ring-lime-300 dark:focus:ring-lime-800"
              onClick={() => setFavoris([true, item])} // passer les lignes et directions à la popup
            >
              <FontAwesomeIcon icon={faStar} />{" "}
              {selectedFavorites.length > 0
                ? "Gérer les favoris"
                : "Ajouter aux favoris"}
            </button>
          ) : (
            <Link to={`/ProchainsPassages?arret=${arret.replace("&", "%26")}`}>
              <button
                type="button"
                className="mb-2 me-2 rounded-full bg-orange-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-orange-800 focus:outline-none focus:ring-4 focus:ring-orange-300"
              >
                Afficher les autres lignes/directions
              </button>
            </Link>
          )}
        </h1>
      </center>
      {ligne && LAdirection && (
        <div className="mb-5">
          <p className="font-bold">
            - Affichage uniquement de :{" "}
            <u>
              {GTFS.routes.find((route) => route.id === ligne)?.name}, direction{" "}
              {GTFS.routes
                .find((route) => route.id === ligne)
                ?.terminus.find((element) => element.id === LAdirection)
                ?.direction.toString()
                .replaceAll(",", " / ")}
            </u>
          </p>
          <i>
            - Pour ajouter cette ligne et cette direction dans les favoris,
            clique sur `Afficher les autres lignes/directions` et le bouton sera
            affiché
          </i>
        </div>
      )}
      {groupedTrips !== null && item.length !== 0 ? (
        Object.keys(groupedTrips).length === 0 ? (
          <p key={"nullPsg"}>Aucun passage n'est prévu prochainement</p>
        ) : (
          Object.keys(groupedTrips).map((routeId) =>
            Object.keys(groupedTrips[routeId]).map((directionId) => {
              const route = GTFS.routes.find((route) => route.id === routeId);
              const direction = route?.terminus
                .find((terminus) => terminus.id === directionId)
                ?.direction.toString()
                .replaceAll(",", " / ");

              return (
                <div
                  key={routeId + directionId}
                  className="mt-2 rounded-xl bg-white px-4 py-2"
                >
                  <span className="flex flex-wrap items-center justify-center">
                    <img
                      src={`https://carto-maas.infotbm.com/assets/images/lines/${route?.name_short}.svg`}
                      alt={`Logo : ${route?.name}`}
                      title={`Logo : ${route?.name}`}
                      className="lignes mb-1"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="20"
                      width="20"
                      viewBox="0 0 512 512"
                      className="mb-1 mr-1 inline-flex"
                    >
                      <path
                        fill="#74C0FC"
                        d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zm395.3 11.3l-112 112c-4.6 4.6-11.5 5.9-17.4 3.5s-9.9-8.3-9.9-14.8l0-64-96 0c-17.7 0-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32l96 0 0-64c0-6.5 3.9-12.3 9.9-14.8s12.9-1.1 17.4 3.5l112 112c6.2 6.2 6.2 16.4 0 22.6z"
                      />
                    </svg>
                    <span>{direction}</span>
                  </span>
                  <hr />
                  <ul className="mt-2 flex flex-col justify-center">
                    {groupedTrips[routeId][directionId].map(
                      (
                        { time, status, color, isRealTime, LAdirection },
                        index,
                      ) => {
                        if (
                          status.includes("Arrêt non desservi") ||
                          status.includes("🚫")
                        ) {
                          return (
                            <li key={index} className="flex items-center">
                              <p
                                className="decoration-ye-400 px-4 py-2"
                                dangerouslySetInnerHTML={{ __html: status }}
                                style={{
                                  color: color,
                                }}
                              ></p>
                            </li>
                          );
                        } else {
                          return (
                            <li
                              key={index}
                              className="flex items-center justify-between"
                            >
                              <span className="w-1/2">
                                {LAdirection ? LAdirection : direction}
                              </span>
                              <div className="group relative inline-block">
                                {isRealTime && (
                                  <FontAwesomeIcon
                                    icon={faWifi}
                                    className="mr-2 animate-pulse text-blue-500"
                                  />
                                )}
                                <span className="mr-5">
                                  {time === "0 min" ? "Proche" : time}
                                </span>
                                <button
                                  type="button"
                                  className="mt-2 transform rounded-full bg-teal-500 px-4 py-2 text-white transition duration-300 ease-in-out hover:scale-105 hover:bg-teal-600"
                                  title="Statut du véhicule"
                                >
                                  <FontAwesomeIcon icon={faClockRotateLeft} />
                                </button>
                                <div className="invisible absolute z-10 rounded-lg border border-gray-300 bg-white py-2 text-gray-800 opacity-0 shadow-lg group-hover:visible group-hover:opacity-100">
                                  {isRealTime ||
                                  (!isRealTime &&
                                    !status.includes("🟢 À l'heure")) ? (
                                    <p
                                      className="decoration-ye-400 px-4 py-2"
                                      dangerouslySetInnerHTML={{
                                        __html: status,
                                      }}
                                      style={{
                                        color: color,
                                      }}
                                    ></p>
                                  ) : (
                                    <p
                                      className="decoration-ye-400 px-4 py-2 italic"
                                      style={{
                                        color: "grey",
                                      }}
                                    >
                                      Horaire théorique
                                    </p>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        }
                      },
                    )}
                  </ul>
                </div>
              );
            }),
          )
        )
      ) : (
        <div className="text-center">
          <div role="status">
            <svg
              aria-hidden="true"
              className="inline h-8 w-8 animate-spin fill-yellow-400 text-gray-200"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default LAPage;
