import React from "react";
import ReactDOM from "react-dom/client";
import {
  HashRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./index.css";
import App from "./App";
import LANav from "./scripts/LANav";
import Accueil from "./scripts/Accueil";
import Carte from "./scripts/Carte";
import InfoTrafic from "./scripts/InfoTrafic";
import Init from "./scripts/Init";
import LaCarte from "./scripts/LaCarte";
import Parametres from "./scripts/Parametres";
import ProchainsPassages from "./scripts/ProchainsPassages";
import NotFound from "./scripts/NotFound"; // Import du composant 404

const Layout = () => {
  const location = useLocation();
  const includedPaths = [
    "/Accueil",
    "/Carte",
    "/InfoTrafic",
    "/LaCarte",
    "/Parametres",
    "/ProchainsPassages",
  ];

  return (
    <>
      {includedPaths.includes(location.pathname) && <LANav />}
      <Routes>
        <Route path="/" element={<App />} /> {/* Route par défaut */}
        <Route path="/Accueil" element={<Accueil />} />
        <Route path="/Carte" element={<Carte />} />
        <Route path="/InfoTrafic" element={<InfoTrafic />} />
        <Route path="/Init" element={<Init />} />
        <Route path="/LaCarte" element={<LaCarte />} />
        <Route path="/Parametres" element={<Parametres />} />
        <Route path="/ProchainsPassages" element={<ProchainsPassages />} />
        <Route path="*" element={<NotFound />} /> {/* Route 404 */}
      </Routes>
    </>
  );
};

document.body.className = "bg-gradient-to-r from-indigo-400 to-cyan-400";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Router>
      <Layout />
    </Router>
  </React.StrictMode>,
);
