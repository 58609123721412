export const typeVehicule = (numero: number | string, ligne: string) => {
  let type, modele, exploitant;
  if (ligne[0] !== "S" || (ligne[0] === "S" && ligne[1] === "t")) {
    numero = Number(numero);
    if (
      (ligne !== "BAT3" &&
        ligne !== "A" &&
        ligne !== "B" &&
        ligne !== "C" &&
        ligne !== "D") ||
      ligne.includes("Bus")
    ) {
      // Bus/cars
      if (numero >= 1001 && numero <= 1010) {
        type = "Bus simple";
        modele = "<span class='modeleVeh'>Heuliez Bus</span> GX 327";
        exploitant = "Keolis Bordeaux Métropole Mobilités";
      } else if (
        (numero >= 1051 && numero <= 1080) ||
        (numero >= 2851 && numero <= 2863)
      ) {
        type = "Bus double";
        modele = "<span class='modeleVeh'>Heuliez Bus</span> GX 427";
        exploitant = "Keolis Bordeaux Métropole Mobilités";
      } else if (numero >= 1120 && numero <= 1149) {
        type = "Bus simple";
        modele = "<span class='modeleVeh'>Heuliez Bus</span> GX 327 Hybrid";
        exploitant = "Keolis Bordeaux Métropole Mobilités";
      } else if (
        (numero >= 1190 && numero <= 1199) ||
        (numero >= 2790 && numero <= 2799) ||
        (numero >= 2891 && numero <= 2892) ||
        numero === 93210 ||
        (numero >= 97002 && numero <= 97007)
      ) {
        type = "Bus court";
        modele = "<span class='modeleVeh'>Heuliez Bus</span> GX 127";
        exploitant = "Keolis Bordeaux Métropole Mobilités";
      } else if (
        (numero >= 1401 && numero <= 1426) ||
        (numero >= 1861 && numero <= 1897)
      ) {
        type = "Bus double";
        modele = "<span class='modeleVeh'>MAN</span> Lion's City G A23 GNC";
        exploitant = "Keolis Bordeaux Métropole Mobilités";
      } else if (
        (numero >= 1501 && numero <= 1530) ||
        (numero >= 1601 && numero <= 1635) ||
        (numero >= 1701 && numero <= 1727)
      ) {
        type = "Bus simple";
        modele = "<span class='modeleVeh'>MAN</span> Lion's City A21 GNC";
        exploitant = "Keolis Bordeaux Métropole Mobilités";
      } else if (numero >= 1598 && numero <= 1599) {
        type = "Bus court";
        modele = "<span class='modeleVeh'>Heuliez Bus</span> GX 137";
        exploitant = "Keolis Bordeaux Métropole Mobilités";
      } else if (numero >= 1898 && numero <= 1899) {
        type = "Minibus";
        modele = "<span class='modeleVeh'>Dietrich</span> City 23";
        exploitant = "Citram Aquitaine";
      } else if (
        (numero >= 2151 && numero <= 2184) ||
        (numero >= 2311 && numero <= 2344)
      ) {
        type = "Bus double";
        modele = "<span class='modeleVeh'>Iveco Bus</span> Urbanway 18 GNC";
        exploitant = "Keolis Bordeaux Métropole Mobilités";
      } else if (
        (numero >= 2551 && numero <= 2565) ||
        (numero >= 2701 && numero <= 2720)
      ) {
        type = "Bus simple";
        modele = "<span class='modeleVeh'>Heuliez Bus</span> GX 327 GNC";
        exploitant = "Keolis Bordeaux Métropole Mobilités";
      } else if (numero >= 2601 && numero <= 2696) {
        type = "Bus double";
        modele = "<span class='modeleVeh'>Irisbus</span> Citelis 18 GNC";
        exploitant = "Keolis Bordeaux Métropole Mobilités";
      } else if (numero >= 2751 && numero <= 2758) {
        type = "Bus double";
        modele =
          "<span class='modeleVeh'>Mercedes-Benz</span> Citaro G Facelift GNC";
        exploitant = "Keolis Bordeaux Métropole Mobilités";
      } else if (
        (numero >= 79012 && numero <= 79023) ||
        (numero >= 79025 && numero <= 79029) ||
        (numero >= 79119 && numero <= 79126) ||
        (numero >= 79136 && numero <= 79141) ||
        numero === 79143 ||
        (numero >= 79158 && numero <= 79160) ||
        (numero >= 99112 && numero <= 99117) ||
        (numero >= 99133 && numero <= 99137) ||
        (numero >= 99140 && numero <= 99145)
      ) {
        type = "Bus simple";
        modele = "<span class='modeleVeh'>Heuliez Bus</span> GX 337";
        exploitant = "Keolis Gironde";
      } else if (numero >= 80001 && numero <= 80008) {
        type = "Bus simple";
        modele =
          "<span class='modeleVeh'>MAN</span> Lion's City 12 G EfficientHybrid";
        exploitant = "Citram Aquitaine";
      } else if (
        (numero >= 86800 && numero <= 86822) ||
        (numero >= 93208 && numero <= 93209) ||
        (numero >= 93211 && numero <= 93240)
      ) {
        type = "Bus simple";
        modele = "<span class='modeleVeh'>Irisbus</span> Crossway LE City";
        if (
          (numero >= 93208 && numero <= 93209) ||
          (numero >= 93211 && numero <= 93240)
        )
          exploitant = "Keolis Gironde";
        else exploitant = "Citram Aquitaine";
      } else if (numero >= 86840 && numero <= 86860) {
        numero = numero.toString().substring(1, 5);
        type = "Bus simple";
        modele = "<span class='modeleVeh'>Iveco Bus</span> Crossway LE City";
        exploitant = "Citram Aquitaine";
      } else if (numero >= 87021 && numero <= 87025) {
        numero = "23" + numero.toString().substring(1, 5);
        type = "Bus court";
        modele =
          "<span class='modeleVeh'>Mercedes-Benz</span> Citaro K C2 Hybrid";
        exploitant = "Keolis Gironde";
      } else if (numero >= 88700 && numero <= 88701) {
        type = "Bus court";
        modele = "<span class='modeleVeh'>Otokar</span> Vectio C";
        exploitant = "Citram Aquitaine";
      } else if (
        (numero >= 89012 && numero <= 89016) ||
        (numero >= 89020 && numero <= 89026) ||
        (numero >= 89033 && numero <= 89040) ||
        (numero >= 89048 && numero <= 89049) ||
        (numero >= 89056 && numero <= 89058) ||
        (numero >= 89083 && numero <= 89087) ||
        (numero >= 89091 && numero <= 89094) ||
        (numero >= 89104 && numero <= 89106) ||
        (numero >= 89115 && numero <= 89125)
      ) {
        type = "Bus simple";
        modele =
          "<span class='modeleVeh'>Mercedes-Benz</span> Citaro C2 Hybrid";
        exploitant = "Keolis Gironde";
      } else if (
        (numero >= 99003 && numero <= 99010) ||
        (numero >= 99012 && numero <= 99048) ||
        (numero >= 99093 && numero <= 99099) ||
        (numero >= 99150 && numero <= 99151) ||
        (numero >= 99160 && numero <= 99164)
      ) {
        type = "Bus simple";
        modele = "<span class='modeleVeh'>Mercedes-Benz</span> Citaro Facelift";
        exploitant = "Keolis Gironde";
      } else if (numero === 60002) {
        type = "Minibus";
        modele = "<span class='modeleVeh'>Rosero</span> First FCLEI";
        exploitant = "SARL Evadys";
      } else if (numero >= 68201 && numero <= 68205) {
        type = "Bus court";
        modele = "<span class='modeleVeh'>Anadolu Isuzu</span> Citiport";
        exploitant = "SARL Evadys";
      } else if (numero >= 65701 && numero <= 65703) {
        type = "Bus court";
        modele = "<span class='modeleVeh'>Iveco Bus</span> Urbanway 12";
        exploitant = "SARL Evadys";
      } else {
        type = "Bus";
        modele = "Inconnu";
        exploitant = "Inconnu";
      }
    } else if (ligne !== "BAT3") {
      // Trams
      if (
        (numero >= 2241 && numero <= 2246) ||
        (numero >= 2541 && numero <= 2546)
      ) {
        type = "Tram court";
        modele = "<span class='modeleVeh'>Alstom</span> Citadis 302";
        exploitant = "Keolis Bordeaux Métropole Mobilités";
      } else if (
        (numero >= 1301 && numero <= 1326) ||
        (numero >= 1827 && numero <= 1856) ||
        (numero >= 2201 && numero <= 2232) ||
        (numero >= 2301 && numero <= 2306) ||
        (numero >= 2501 && numero <= 2520) ||
        (numero >= 2801 && numero <= 2804)
      ) {
        type = "Tram long";
        modele = "<span class='modeleVeh'>Alstom</span> Citadis 402";
        exploitant = "Keolis Bordeaux Métropole Mobilités";
      } else {
        type = "Tram";
        modele = "Inconnu";
        exploitant = "Inconnu";
      }
    } else {
      // Bateaux
      type = "Navette fluviale";

      switch (numero) {
        case 6901:
          modele = `Catamaran "L'Hirondelle"`;
          exploitant = "Bordeaux River Cruise";
          break;
        case 6902:
          modele = `Catamaran "La Gondole"`;
          exploitant = "Bordeaux River Cruise";
          break;
        case 6903:
          modele = `Bateau "La Mouette"`;
          exploitant = "Bordeaux River Cruise";
          break;
        case 6904:
          modele = `Bateau "Le Silnet"`;
          exploitant = "Bordeaux River Cruise";
          break;
        default:
          modele = "Inconnu";
          exploitant = "Inconnu";
          break;
      }
    }
  } else {
    type = "Bus Scolaire";
    // S30 -> 4f93f8e5f0bd2860
    // S21 -> 64be1caca19aa09bafea62bf

    switch (numero) {
      case "64f1e457576602100fb20cd8":
        modele = "<span class='modeleVeh'>Mercedes-Benz</span> Intouro II E";
        exploitant = "Transhorizon";
        break;
      case "12179b9d35fce119":
      case "64f1ee51feb5ef65f8499850":
      case "64f1df5ac2e9d746631ee9df":
      case "64be20f83c252ee3e027d828":
        modele = "<span class='modeleVeh'>Iveco Bus</span> Crossway Pop";
        exploitant = "Citram Aquitaine";
        break;
      case "64f1dfa3acba2fb10ecd8a17":
        modele = "<span class='modeleVeh'>Mercedes-Benz</span> Intouro II L";
        exploitant = "Citram Aquitaine";
        break;
      case "64be1e95b290ac98c1708cfc":
        modele = "<span class='modeleVeh'>Mercedes-Benz</span> Intouro II M";
        exploitant = "Keolis Gironde";
        break;
      case "64f0a768acba2fb10ecd89f9":
      case "64f1e414e3bb618bad93b04a":
        modele = "<span class='modeleVeh'>Otokar</span> Territo U";
        exploitant = "Transcom Voyages";
        break;
      case "3228c39a21eaf710":
      case "64f55cd3e3bb618bad93b065":
        modele = "<span class='modeleVeh'>Irisbus</span> Récréo II";
        exploitant = "Keolis Gironde";
        break;

      default:
        modele = "Inconnu";
        exploitant = "Inconnu";
        break;
    }
  }
  return { type, modele, exploitant };
};
