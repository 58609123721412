import {
  faArrowUpRightFromSquare,
  faBug,
  faCircleExclamation,
  faEye,
  faEyeSlash,
  faPencil,
  faPlus,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import protobuf, { Root } from "protobufjs";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { readFile, saveFile } from "./deps/OPFS";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { stopsRequete } from "./deps/DoNotOPFS";

type VehicleData = {
  id: string;
  vehicle: {
    currentStatus: string;
    currentStopSequence: number;
    position: {
      latitude: number;
      longitude: number;
      speed: number;
    };
    stopId: string;
    timestamp: string;
    trip?: {
      directionId: number;
      routeId: string;
      tripId: string;
    };
    vehicle: {
      id: string;
      label: string;
    };
  };
};

type TripUpdate = {
  id: string;
  tripUpdate: {
    stopTimeUpdate: {
      arrival?: {
        delay: number;
        time: string;
      };
      departure?: {
        delay: number;
        time: string;
      };
      scheduleRelationship: string;
      stopId: string;
      stopSequence: number;
    }[];
    timestamp: string;
    trip: {
      directionId: number;
      routeId: string;
      scheduleRelationship: string;
      startDate: string;
      tripId: string;
    };
    vehicle?: {
      id: string;
      label: string;
    };
  };
};

interface Passage {
  ligne: string[];
  direction: string;
  passages: string;
  realTime: boolean;
  statut: string;
}

// Lignes favorites
const LignesFavoritesString = localStorage.getItem("LignesFavorites");
const LignesFavorites: string[][] = LignesFavoritesString
  ? JSON.parse(LignesFavoritesString)
  : [];

// Arrêts favoris
const ArretsFavorisString = localStorage.getItem("ArretsFavoris");
const ArretsFavoris: { [key: string]: string[] } = ArretsFavorisString
  ? JSON.parse(ArretsFavorisString)
  : {};

const Statut_Server = async (): Promise<boolean> => {
  // Test de connexion vers le serveur
  try {
    const response = await fetch(
      `https://bdx.mecatran.com/utw/ws/siri/2.0/bordeaux/check-status.json`,
    );
    const TEST = await response.json();
    return TEST.Siri.CheckStatusDelivery.Status;
  } catch {
    return false;
  }
};

const LAPage = () => {
  // La page
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const OPFSString = localStorage.getItem("OPFS");
  const OPFS: {
    routes?: string;
    shapes?: string;
    stops?: string;
  } = OPFSString ? JSON.parse(OPFSString) : {};
  const [maj, setMaj] = useState<true | false>(false);
  const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000; // 172800000 ms
  const [serverStatus, setServerStatus] = useState<boolean | null>(null);
  const [Vehicles, setVehicles] = useState<string[] | null | false>(null);
  const [passagesParArret, setPassagesParArret] = useState<
    | {
        [arret: string]: {
          ligne: string[];
          direction: string;
          passages: string;
          realTime: boolean;
          statut: string;
        }[];
      }
    | null
    | undefined
  >(null);
  const [erreur, setErreur] = useState<[boolean, null | any]>([false, null]);
  const [view, setView] = useState<boolean>(false);

  useEffect(() => {
    const checkServerStatus = async () => {
      const status = await Statut_Server();
      setServerStatus(status);
    };

    checkServerStatus();
  }, []);

  useEffect(() => {
    const requetes = async () => {
      if (LignesFavorites.length > 0) {
        try {
          // GTFS-RT Vehicles
          const response = await fetch(
            "https://bdx.mecatran.com/utw/ws/gtfsfeed/vehicles/bordeaux?apiKey=opendata-bordeaux-metropole-flux-gtfs-rt",
          );
          const data = await response.arrayBuffer();
          const root: Root = await new Promise((resolve, reject) => {
            protobuf.load(
              "./ressources/GTFS/fichier.proto",
              (ERRfatal: any, root: any) => {
                if (ERRfatal) {
                  reject(ERRfatal);
                } else {
                  resolve(root);
                }
              },
            );
          });
          const FeedMessage = root.lookupType("transit_realtime.FeedMessage");
          const message = FeedMessage.decode(new Uint8Array(data));
          const pro = JSON.stringify(message, null, 2);
          const vehicles: string[] = [];
          JSON.parse(pro).entity.forEach((element: VehicleData) => {
            if (element.vehicle.trip) {
              vehicles.push(element.vehicle.trip.routeId);
            }
          });

          setVehicles(vehicles);
        } catch (ERRfatal) {
          console.error(ERRfatal);
          setErreur([true, ERRfatal]);
          setVehicles(false);
        }
      }

      if (Object.keys(ArretsFavoris).length > 0) {
        let tripUpdates: TripUpdate[] | undefined;
        try {
          const response = await fetch(
            "https://bdx.mecatran.com/utw/ws/gtfsfeed/realtime/bordeaux?apiKey=opendata-bordeaux-metropole-flux-gtfs-rt",
          );
          const data = await response.arrayBuffer();
          const root: Root = await new Promise((resolve, reject) => {
            protobuf.load(
              "./ressources/GTFS/fichier.proto",
              (ERRfatal: any, root: any) => {
                if (ERRfatal) {
                  reject(ERRfatal);
                } else {
                  resolve(root);
                }
              },
            );
          });
          const FeedMessage = root.lookupType("transit_realtime.FeedMessage");
          const message = FeedMessage.decode(new Uint8Array(data));
          tripUpdates = JSON.parse(JSON.stringify(message)).entity;
        } catch (ERRfatal) {
          console.error(ERRfatal);
          setErreur([true, ERRfatal]);
          setPassagesParArret(undefined);
          return;
        }

        let stops: {
          id: string[];
          lignes: string[];
          name: string;
          position: number[];
        }[];
        try {
          if (
            !isSafari &&
            navigator.storage &&
            Object.keys(OPFS).length !== 0
          ) {
            const mtn = Date.now().toString();

            // stops.json
            if (
              OPFS.stops &&
              Date.now() - parseInt(OPFS.stops, 10) < twoDaysInMilliseconds
            ) {
              stops = await readFile("stops.json");
            } else {
              setMaj(true);
              const requete2 = await stopsRequete();
              if (!requete2) {
                setErreur([true, "TypeError: Failed to fetch"]);
              } else {
                await saveFile("stops.json", requete2);
                OPFS.stops = mtn;
                localStorage.setItem("OPFS", JSON.stringify(OPFS));
                stops = requete2;
              }
            }

            if (maj) {
              window.location.reload();
            }
          } else {
            const requete1 = await stopsRequete();

            if (!requete1) {
              setErreur([true, "TypeError: Failed to fetch"]);
            } else {
              stops = requete1;
            }
          }
        } catch (ERRfatal) {
          console.error(ERRfatal);
          setErreur([true, ERRfatal]);
          setPassagesParArret(undefined);
          return;
        }

        const newPassagesParArret: {
          [arret: string]: {
            ligne: string[];
            direction: string;
            passages: string;
            realTime: boolean;
            statut: string;
          }[];
        } = {};

        Object.keys(ArretsFavoris).forEach((nameStop) => {
          const qqch:
            | {
                id: string[];
                lignes: string[];
                name: string;
                position: number[];
              }
            | undefined = stops.find((Lelement) => Lelement.name === nameStop);
          tripUpdates?.forEach((LEtripUpdate) => {
            const isVehicleCanceled =
              LEtripUpdate.tripUpdate.stopTimeUpdate.every(
                (update) => update.scheduleRelationship === "SKIPPED",
              );
            if (isVehicleCanceled) {
              return;
            }

            ArretsFavoris[nameStop].forEach((direction_lignes) => {
              direction_lignes = JSON.parse(direction_lignes);
              if (
                parseInt(direction_lignes[1][0]) ===
                  LEtripUpdate.tripUpdate.trip.directionId &&
                direction_lignes[0][2] === LEtripUpdate.tripUpdate.trip.routeId
              ) {
                LEtripUpdate.tripUpdate.stopTimeUpdate.forEach(
                  (stopTimeUpdate) => {
                    if (qqch?.id.includes(stopTimeUpdate.stopId)) {
                      let passageInfo;
                      if (stopTimeUpdate.scheduleRelationship === "SKIPPED") {
                        // Vérifier si l'arrêt est desservi avant d'être marqué comme non desservi
                        const hasServedStop =
                          LEtripUpdate.tripUpdate.stopTimeUpdate.some(
                            (update) =>
                              update.scheduleRelationship === "SCHEDULED",
                          );
                        if (hasServedStop) {
                          return;
                        }

                        passageInfo = {
                          ligne: [
                            direction_lignes[0][0],
                            direction_lignes[0][1],
                          ],
                          direction: direction_lignes[1][1],
                          passages: "⚠️ Arrêt non desservi !",
                          realTime: false,
                          statut: "",
                        };
                      } else {
                        let status = "🟢 À l'heure";
                        let timeDifference = 0;
                        let exactTime = "";
                        let delayInMinutes = 0;

                        // Calculer l'heure d'arrivée ou de départ
                        if (
                          stopTimeUpdate.arrival &&
                          stopTimeUpdate.arrival.time
                        ) {
                          const arrivalTime = new Date(
                            parseInt(stopTimeUpdate.arrival.time) * 1000,
                          );
                          timeDifference = Math.floor(
                            (arrivalTime.getTime() - new Date().getTime()) /
                              60000,
                          );
                          exactTime = arrivalTime.toLocaleTimeString("fr-FR", {
                            hour: "2-digit",
                            minute: "2-digit",
                          });
                          delayInMinutes = Math.floor(
                            (stopTimeUpdate.arrival.delay || 0) / 60,
                          );
                        } else if (
                          stopTimeUpdate.departure &&
                          stopTimeUpdate.departure.time
                        ) {
                          const departureTime = new Date(
                            parseInt(stopTimeUpdate.departure.time) * 1000,
                          );
                          timeDifference = Math.floor(
                            (departureTime.getTime() - new Date().getTime()) /
                              60000,
                          );
                          exactTime = departureTime.toLocaleTimeString(
                            "fr-FR",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            },
                          );
                          delayInMinutes = Math.floor(
                            (stopTimeUpdate.departure.delay || 0) / 60,
                          );
                        }

                        if (timeDifference < 0) {
                          return;
                        }

                        // Déterminer le statut en fonction du délai
                        if (delayInMinutes > -1 && delayInMinutes < 1) {
                          status = "🟢 À l'heure";
                        } else if (delayInMinutes >= 1) {
                          status = `⚠️ Retard`;
                        } else if (delayInMinutes <= -1) {
                          status = `⚠️ Avance`;
                        }

                        const realTime = !!LEtripUpdate.tripUpdate.vehicle;

                        passageInfo = {
                          ligne: [
                            direction_lignes[0][0],
                            direction_lignes[0][1],
                          ],
                          direction: LEtripUpdate.tripUpdate.vehicle
                            ? LEtripUpdate.tripUpdate.vehicle.label
                            : direction_lignes[1][1],
                          passages:
                            timeDifference > 59
                              ? exactTime
                              : `${timeDifference} min`,
                          realTime,
                          statut: status,
                        };
                      }

                      if (passageInfo.passages.includes("⚠️")) {
                        if (!newPassagesParArret[nameStop]) {
                          newPassagesParArret[nameStop] = [passageInfo];
                        } else if (
                          newPassagesParArret[nameStop].filter(
                            (element) =>
                              element.ligne[0] === passageInfo.ligne[0] &&
                              element.direction === passageInfo.direction,
                          ).length === 0
                        ) {
                          newPassagesParArret[nameStop].push(passageInfo);
                        }
                      } else {
                        if (!newPassagesParArret[nameStop]) {
                          newPassagesParArret[nameStop] = [passageInfo];
                        } else {
                          newPassagesParArret[nameStop].push(passageInfo);
                        }
                      }
                    }
                  },
                );
              }
            });
          });
          if (!newPassagesParArret[nameStop]) {
            newPassagesParArret[nameStop] = [];
          }
        });
        Object.keys(newPassagesParArret).forEach((element) => {
          // Trier les éléments
          newPassagesParArret[element].sort((a, b) => {
            const timeA = a.passages.includes(":")
              ? parseInt(a.passages.split(":")[0] + a.passages.split(":")[1])
              : a.passages.includes("⚠️")
                ? 9999
                : parseInt(a.passages);
            const timeB = b.passages.includes(":")
              ? parseInt(b.passages.split(":")[0] + b.passages.split(":")[1])
              : b.passages.includes("⚠️")
                ? 9999
                : parseInt(b.passages);
            return timeA - timeB;
          });

          // Filtrer pour ne garder que 3 éléments par ligne[0] et direction
          const filtered = newPassagesParArret[element].reduce(
            (acc, current) => {
              const key = `${current.ligne[0]}-${current.direction.toLowerCase().split(" ").toString()}`;
              if (!acc[key]) {
                acc[key] = [];
              }
              if (acc[key].length < 3) {
                acc[key].push(current);
              }
              return acc;
            },
            {} as { [key: string]: Passage[] },
          );

          // Convertir l'objet filtré en tableau
          newPassagesParArret[element] = Object.values(filtered).flat();
        });
        setPassagesParArret(newPassagesParArret);
      }
    };

    if (
      (LignesFavorites.length > 0 && Vehicles === null) ||
      (Object.keys(ArretsFavoris).length > 0 && passagesParArret === null)
    ) {
      if (!erreur[0] && serverStatus !== false) {
        requetes();
      }
    }
  });

  return (
    <main>
      {erreur[0] && (
        <Dialog
          open={true}
          onClose={() => setErreur([false, null])}
          className="relative z-10"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-300 sm:mx-0 sm:h-10 sm:w-10">
                      <FontAwesomeIcon icon={faBug} />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Erreur
                      </DialogTitle>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Une erreur{" "}
                          <b>
                            {String(erreur[1]).includes("fetch")
                              ? "lors d'une requête"
                              : "inconnue"}
                          </b>{" "}
                          est survenue.{" "}
                          {String(erreur[1]).includes("fetch")
                            ? "Vérifie ta connexion Internet et actualise la page"
                            : "Patiente quelques instants puis actualise la page"}
                          <br />
                          <span
                            className="flex justify-start text-blue-500"
                            onClick={() => setView(!view)}
                          >
                            {view ? (
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                className="mr-1"
                              />
                            ) : (
                              <FontAwesomeIcon icon={faEye} className="mr-1" />
                            )}
                            {view
                              ? "Cacher les détails"
                              : "Afficher les détails"}
                          </span>
                          {view && (
                            <span className="flex justify-start text-sm italic text-gray-500">
                              {String(erreur[1])}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <Link to="/Accueil" onClick={() => window.location.reload()}>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-300 sm:ml-3 sm:w-auto"
                    >
                      Actualiser la page
                    </button>
                  </Link>
                  <button
                    type="button"
                    onClick={() => setErreur([false, null])}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Fermer
                  </button>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      )}
      {serverStatus === false && (
        // En cas d'erreur lors de la connexion vers le serveur
        <div
          id="notConnexion"
          className="mb-4 w-full rounded-lg border border-red-300 bg-red-50 p-4 text-red-800"
          role="alert"
        >
          <div className="flex items-center">
            <FontAwesomeIcon icon={faCircleExclamation} className="mr-1" />
            <span className="sr-only">Erreur</span>
            <h3 className="text-lg font-bold underline">
              Échec de la connexion au serveur
            </h3>
          </div>
          <div className="mb-4 mt-2 text-sm">
            Des erreurs peuvent survenir lors de l'utilisation de TBpM
            <br />
            <br />
            Ce que tu peux faire :
            <ul className="mt-1.5 list-inside list-disc">
              <li>Vérifie ta connexion Internet</li>
              <li>
                Si tu as Internet, patiente quelques instants et actualise la
                page
              </li>
              <li>
                Si tu me vois encore même si t'as Internet, cela doit être
                sûrement un problème du côté du serveur
              </li>
            </ul>
          </div>
          <div className="flex">
            <button
              type="button"
              className="rounded-lg border border-red-800 bg-transparent px-3 py-1.5 text-center text-xs font-medium text-red-800 hover:bg-red-900 hover:text-white focus:outline-none focus:ring-4 focus:ring-red-300"
              data-dismiss-target="#alert-additional-content-2"
              aria-label="Close"
              onClick={() =>
                document
                  .getElementById("notConnexion")
                  ?.style.setProperty("display", "none")
              }
            >
              Cacher
            </button>
          </div>
        </div>
      )}
      <div className="rounded-md border-b border-gray-200 bg-slate-100 px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Véhicules en service
            </h3>
          </div>
          <div className="ml-4 mt-2 flex-shrink-0">
            <Link key={"AjoutLignes"} to="/Carte?favoris=true">
              <button
                type="button"
                className="relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                title="Ajouter des lignes favorites"
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>{" "}
            </Link>
            {LignesFavorites.length > 0 && (
              <Link key={"ModifLignes"} to="/Parametres">
                <button
                  type="button"
                  className="relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  title="Modifier l'ordre de tes lignes favorites"
                >
                  <FontAwesomeIcon icon={faPencil} />
                </button>
              </Link>
            )}
          </div>
        </div>
        <div>
          {LignesFavorites.length > 0 ? (
            LignesFavorites.map((element) => (
              <Link
                key={element[0]}
                to={`/LaCarte?ligne=${element[0] === "9999" ? "BRT" : element[0]}`}
                className={`relative ${LignesFavorites.indexOf(element) === 0 ? "me-0" : ""}`}
              >
                <button
                  type="button"
                  className="inline-flex items-center rounded-full border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  title={`${element[1]}`}
                >
                  {element[0] === "9999" ? (
                    <img
                      className="lignes"
                      src={`./ressources/BRT/BRT.svg`}
                      alt={`Logo : ${element[2]}`}
                      title={`Logo : ${element[2]}`}
                    />
                  ) : (
                    <img
                      className="lignes"
                      src={`https://carto-maas.infotbm.com/assets/images/lines/${element[1]}.svg`}
                      alt={`Logo : ${element[2]}`}
                      title={`Logo : ${element[2]}`}
                    />
                  )}
                </button>{" "}
                {Vehicles === null || Vehicles === false ? (
                  <div className="absolute -top-5 start-12 h-3.5 w-3.5 animate-pulse rounded-full border-2 border-white bg-blue-200"></div>
                ) : Vehicles.includes(element[0]) ? (
                  <span className="absolute -top-5 start-12 h-3.5 w-3.5 rounded-full border-2 border-white bg-green-500"></span>
                ) : (
                  element[0] !== "9999" &&
                  !Vehicles.includes(element[0]) && (
                    <span className="absolute -top-5 start-12 h-3.5 w-3.5 rounded-full border-2 border-white bg-red-500"></span>
                  )
                )}
              </Link>
            ))
          ) : (
            <p>Tu n'as pas de lignes favorites</p>
          )}
        </div>
        <div className="mt-3 inline-flex">
          <span className="flex h-3 w-3 rounded-full bg-green-500"></span>
          <span className="ml-1 inline-flex">= En service</span>
          <span className="ml-3 flex h-3 w-3 rounded-full bg-red-500"></span>
          <span className="ml-1 inline-flex">= Hors service</span>
        </div>
      </div>
      <br />
      <div className="rounded-md border-b border-gray-200 bg-slate-100 px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Prochains passages
            </h3>
          </div>
          <div className="ml-4 mt-2 flex-shrink-0">
            <Link key={"AjoutArrets"} to="/ProchainsPassages">
              <button
                type="button"
                className="relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                title="Ajouter des arrêts favoris"
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>{" "}
            </Link>
            {LignesFavorites.length > 0 && (
              <Link key={"ModifArrets"} to="/Parametres">
                <button
                  type="button"
                  className="relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  title="Modifier l'ordre de tes arrêts favoris"
                >
                  <FontAwesomeIcon icon={faPencil} />
                </button>
              </Link>
            )}
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {Object.keys(ArretsFavoris).length > 0 ? (
            passagesParArret ? (
              Object.keys(passagesParArret).map((arret) => (
                <div key={arret}>
                  <h4 className="mt-3 inline-flex w-1/2 text-2xl font-bold underline">
                    Arrêt : {arret}
                  </h4>
                  <Link key={arret} to={`/ProchainsPassages?arret=${arret}`}>
                    <button
                      type="button"
                      className="mb-2 me-2 ml-3 inline-flex rounded-lg bg-gradient-to-r from-purple-500 to-pink-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-l focus:outline-none focus:ring-4 focus:ring-purple-200"
                    >
                      Plus de détails
                      <FontAwesomeIcon
                        icon={faArrowUpRightFromSquare}
                        className="ml-2"
                      />
                    </button>
                  </Link>
                  {passagesParArret[arret].length > 0 ? (
                    passagesParArret[arret].map((element) => (
                      <div
                        key={JSON.stringify(element)}
                        className="mt-2 rounded-xl bg-white px-4 py-2"
                      >
                        <span className="flex flex-wrap items-center justify-between">
                          <span className="flex flex-wrap items-center">
                            <img
                              src={`https://carto-maas.infotbm.com/assets/images/lines/${element.ligne[0]}.svg`}
                              alt={`Logo : ${element.ligne[1]}`}
                              title={`Logo : ${element.ligne[1]}`}
                              className="lignes mb-1"
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="20"
                              width="20"
                              viewBox="0 0 512 512"
                              className="mb-1 mr-1 inline-flex"
                            >
                              <path
                                fill="#74C0FC"
                                d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zm395.3 11.3l-112 112c-4.6 4.6-11.5 5.9-17.4 3.5s-9.9-8.3-9.9-14.8l0-64-96 0c-17.7 0-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32l96 0 0-64c0-6.5 3.9-12.3 9.9-14.8s12.9-1.1 17.4 3.5l112 112c6.2 6.2 6.2 16.4 0 22.6z"
                              />
                            </svg>
                            <span>{element.direction}</span>
                          </span>
                          <div className="justify-end">
                            {element.realTime && (
                              <FontAwesomeIcon
                                icon={faWifi}
                                className="mr-2 animate-pulse text-blue-500"
                              />
                            )}
                            <span className="mr-5">
                              {element.passages === "0 min"
                                ? "Proche"
                                : element.passages}
                            </span>
                            {element.realTime ||
                            (!element.realTime &&
                              element.statut !== "🟢 À l'heure") ? (
                              <span>{element.statut}</span>
                            ) : (
                              <span
                                className="decoration-ye-400 px-4 py-2 italic"
                                style={{
                                  color: "grey",
                                }}
                              >
                                Horaire théorique
                              </span>
                            )}
                          </div>
                        </span>
                      </div>
                    ))
                  ) : (
                    <p>Aucun passage n'est prévu prochainement</p>
                  )}
                </div>
              ))
            ) : (
              <div role="status" className="max-w-sm animate-pulse">
                <div className="mb-4 h-2.5 rounded-full bg-gray-200"></div>
              </div>
            )
          ) : (
            <p>Tu n'as pas d'arrêts favoris</p>
          )}
        </div>
      </div>
    </main>
  );
};

export default LAPage;
