import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  faBug,
  faBusSimple,
  faCheck,
  faEye,
  faEyeSlash,
  faFire,
  faMinus,
  faMoon,
  faPencil,
  faPlus,
  faSchool,
  faShip,
  faTrain,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { readFile, saveFile } from "./deps/OPFS";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { routesRequete } from "./deps/DoNotOPFS";

type typeRoutes = {
  id: string;
  name: string;
  name_short: string;
  terminus: object[];
}[];

const LAPage = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const OPFSString = localStorage.getItem("OPFS");
  // eslint-disable-next-line
  const OPFS: {
    routes?: string;
    shapes?: string;
    stops?: string;
  } = OPFSString ? JSON.parse(OPFSString) : {};
  const [maj, setMaj] = useState<true | false>(false);
  const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000; // 172800000 ms
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [favoris, setFavoris] = useState<[boolean, string[][]]>([
    Boolean(new URLSearchParams(location.search).get("favoris")),
    JSON.parse(localStorage.getItem("LignesFavorites") || "null"),
  ]);
  const [routes, setRoutes] = useState<typeRoutes | null>(null);
  const [erreur, setErreur] = useState<[boolean, null | any]>([false, null]);
  const [view, setView] = useState<boolean>(false);

  useEffect(() => {
    const fetchLignes = async () => {
      let routes: {
        id: string;
        name: string;
        name_short: string;
        terminus: {
          direction: string | string[];
          id: string;
        }[];
      }[] = [];
      try {
        if (!isSafari && navigator.storage && Object.keys(OPFS).length !== 0) {
          const mtn = Date.now().toString();

          // routes.json
          if (
            OPFS.routes &&
            Date.now() - parseInt(OPFS.routes, 10) < twoDaysInMilliseconds
          ) {
            routes = await readFile("routes.json");
          } else {
            setMaj(true);
            const requete1 = await routesRequete();
            if (!requete1) {
              setErreur([true, "TypeError: Failed to fetch"]);
            } else {
              await saveFile("routes.json", requete1);
              OPFS.routes = mtn;
              localStorage.setItem("OPFS", JSON.stringify(OPFS));
              routes = requete1;
            }
          }

          if (maj) {
            window.location.reload();
          }

          routes.push({
            id: "9999",
            name: "Bus Relais Tram",
            name_short: "BRT",
            terminus: [],
          });
        } else {
          const requete2 = await routesRequete();

          if (!requete2) {
            setErreur([true, "TypeError: Failed to fetch"]);
          } else {
            routes = requete2;
            routes.push({
              id: "9999",
              name: "Bus Relais Tram",
              name_short: "BRT",
              terminus: [],
            });
          }
        }
      } catch (ERRfatal) {
        console.error(ERRfatal);
        setErreur([true, ERRfatal]);
      }
      routes.sort((a, b) => {
        const aShort = parseInt(a.name_short);
        const bShort = parseInt(b.name_short);

        if (!Number.isNaN(aShort) && !Number.isNaN(bShort)) {
          return aShort - bShort; // Tri numérique
        } else {
          return a.id.localeCompare(b.id); // Tri lexicographique pour les IDs
        }
      });
      setRoutes(routes);
      setLoading(false);
    };

    fetchLignes();
  }, [isSafari, OPFS, twoDaysInMilliseconds, maj]);

  useEffect(() => {
    localStorage.setItem("LignesFavorites", JSON.stringify(favoris[1]));
  }, [favoris]);

  const renderRoutes = (filterCondition: (id: number) => boolean) => {
    if (routes !== null) {
      return routes
        .filter((element) => filterCondition(parseInt(element.id)))
        .map((element) => {
          if (favoris[0] === false) {
            if (element.id === "9999") {
              return (
                <Link key={element.id} to="/LaCarte?ligne=BRT">
                  <img
                    className="lignes m-2 inline-flex"
                    src={`./ressources/BRT/BRT.svg`}
                    title={`Logo : ${element.name}`}
                    alt={`Logo : ${element.name}`}
                  />
                </Link>
              );
            } else {
              return (
                <Link key={element.id} to={`/LaCarte?ligne=${element.id}`}>
                  <img
                    className="lignes m-2 inline-flex"
                    src={`https://carto-maas.infotbm.com/assets/images/lines/${element.name_short}.svg`}
                    title={`Logo : ${element.name}`}
                    alt={`Logo : ${element.name}`}
                  />
                </Link>
              );
            }
          } else {
            let boolean = false;
            favoris[1].forEach((Lelement) => {
              if (Lelement[0] === element.id) {
                boolean = true;
                return;
              }
            });
            return (
              <div key={element.id} className="relative inline-flex">
                {element.id === "9999" ? (
                  <img
                    className="lignes m-2"
                    src={`./ressources/BRT/BRT.svg`}
                    title={`Logo : ${element.name}`}
                    alt={`Logo : ${element.name}`}
                    onClick={() => {
                      const newFavoris: [boolean, string[][]] = [
                        favoris[0],
                        [...favoris[1]],
                      ];
                      if (boolean === false) {
                        newFavoris[1].push([
                          element.id,
                          element.name_short,
                          element.name,
                        ]);
                      } else {
                        newFavoris[1] = newFavoris[1].filter(
                          (Lelement) => Lelement[0] !== element.id,
                        );
                      }
                      setFavoris(newFavoris);
                    }}
                  />
                ) : (
                  <img
                    className="lignes m-2"
                    src={`https://carto-maas.infotbm.com/assets/images/lines/${element.name_short}.svg`}
                    title={`Logo : ${element.name}`}
                    alt={`Logo : ${element.name}`}
                    onClick={() => {
                      const newFavoris: [boolean, string[][]] = [
                        favoris[0],
                        [...favoris[1]],
                      ];
                      if (boolean === false) {
                        newFavoris[1].push([
                          element.id,
                          element.name_short,
                          element.name,
                        ]);
                      } else {
                        newFavoris[1] = newFavoris[1].filter(
                          (Lelement) => Lelement[0] !== element.id,
                        );
                      }
                      setFavoris(newFavoris);
                    }}
                  />
                )}
                {boolean === false ? (
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="absolute start-7 top-0 h-3.5 w-3.5 rounded-full bg-green-500"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faMinus}
                    className="absolute start-7 top-0 h-3.5 w-3.5 rounded-full bg-red-500"
                  />
                )}
              </div>
            );
          }
        });
    }
  };

  // La page
  return (
    <main>
      {erreur[0] && (
        <Dialog
          open={true}
          onClose={() => setErreur([false, null])}
          className="relative z-10"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-300 sm:mx-0 sm:h-10 sm:w-10">
                      <FontAwesomeIcon icon={faBug} />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Erreur
                      </DialogTitle>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Une erreur{" "}
                          <b>
                            {String(erreur[1]).includes("fetch")
                              ? "lors d'une requête"
                              : "inconnue"}
                          </b>{" "}
                          est survenue.{" "}
                          {String(erreur[1]).includes("fetch")
                            ? "Vérifie ta connexion Internet et actualise la page"
                            : "Patiente quelques instants puis actualise la page"}
                          <br />
                          <span
                            className="flex justify-start text-blue-500"
                            onClick={() => setView(!view)}
                          >
                            {view ? (
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                className="mr-1"
                              />
                            ) : (
                              <FontAwesomeIcon icon={faEye} className="mr-1" />
                            )}
                            {view
                              ? "Cacher les détails"
                              : "Afficher les détails"}
                          </span>
                          {view && (
                            <span className="flex justify-start text-sm italic text-gray-500">
                              {String(erreur[1])}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <Link to="/Carte" onClick={() => window.location.reload()}>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-300 sm:ml-3 sm:w-auto"
                    >
                      Actualiser la page
                    </button>
                  </Link>
                  <button
                    type="button"
                    onClick={() => setErreur([false, null])}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Fermer
                  </button>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      )}
      <div className="rounded-md border-b border-gray-200 bg-slate-100 px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Véhicules en service
            </h3>
          </div>
          <div className="ml-4 mt-2 flex-shrink-0">
            <button
              type="button"
              className="relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              title="Modifier tes lignes favorites"
              onClick={() => {
                const newFavoris: [boolean, string[][]] = [
                  !favoris[0],
                  favoris[1],
                ];
                setFavoris(newFavoris);
                if (favoris[0] === true) {
                  navigate("/Carte");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1);
                }
              }}
            >
              {favoris[0] === false ? (
                <>
                  <FontAwesomeIcon icon={faPencil} className="mr-3" /> Gérer les
                  <br />
                  lignes favorites
                </>
              ) : (
                <>
                  {" "}
                  <FontAwesomeIcon icon={faCheck} className="mr-3" /> Valider
                  les
                  <br />
                  modifications
                </>
              )}
            </button>
          </div>
        </div>
        <div>
          {favoris[1].length > 0 ? (
            favoris[1].map((element) =>
              favoris[0] === false ? (
                <Link
                  key={element[0]}
                  to={`/LaCarte?ligne=${element[0] === "9999" ? "BRT" : element[0]}`}
                >
                  <button
                    type="button"
                    className="inline-flex items-center rounded-full border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    title={`${element[2]}`}
                  >
                    {element[0] === "9999" ? (
                      <img
                        className="lignes"
                        src={`./ressources/BRT/BRT.svg`}
                        alt={`Logo : ${element[2]}`}
                        title={`Logo : ${element[2]}`}
                      />
                    ) : (
                      <img
                        className="lignes"
                        src={`https://carto-maas.infotbm.com/assets/images/lines/${element[1]}.svg`}
                        alt={`Logo : ${element[2]}`}
                        title={`Logo : ${element[2]}`}
                      />
                    )}
                  </button>{" "}
                </Link>
              ) : (
                <>
                  <button
                    type="button"
                    className="inline-flex items-center rounded-full border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    title={`${element[2]}`}
                  >
                    {element[0] === "9999" ? (
                      <img
                        className="lignes"
                        src={`./ressources/BRT/BRT.svg`}
                        alt={`Logo : ${element[2]}`}
                        title={`Logo : ${element[2]}`}
                      />
                    ) : (
                      <img
                        className="lignes"
                        src={`https://carto-maas.infotbm.com/assets/images/lines/${element[1]}.svg`}
                        alt={`Logo : ${element[2]}`}
                        title={`Logo : ${element[2]}`}
                      />
                    )}
                  </button>{" "}
                </>
              ),
            )
          ) : (
            <p>Tu n'as pas de lignes favorites</p>
          )}
        </div>
        {favoris[0] === true && (
          <p className="mt-2">
            -{" "}
            <b>
              Valide les modifications pour pouvoir sélectionner une de tes
              lignes favorites !
            </b>
            <br />-{" "}
            <i>
              Pour modifier l'ordre des lignes favorites, va dans les paramètres
            </i>{" "}
            😉
          </p>
        )}
      </div>
      <br />
      <Link to="/LaCarte">
        <button
          type="button"
          className="my-4 me-2 rounded-lg bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 px-5 py-2.5 text-center text-sm font-medium text-gray-900 hover:bg-gradient-to-br focus:outline-none focus:ring-4 focus:ring-lime-300"
        >
          Afficher le réseau
          <img
            className="inline-flex w-16"
            src="./ressources/TBM.png"
            alt="Logo : TBM"
            title="Logo : TBM"
          />
        </button>
      </Link>
      <p>
        <FontAwesomeIcon icon={faTrain} /> Trams
      </p>
      <div id="trams" className="ml-3">
        {loading === true ? (
          <div role="status" className="max-w-sm animate-pulse">
            <div className="mb-4 h-2.5 rounded-full bg-gray-200"></div>
          </div>
        ) : (
          renderRoutes((id) => id >= 59 && id <= 62)
        )}
      </div>
      <p>
        <FontAwesomeIcon icon={faBusSimple} /> Bus
      </p>
      <center>
        <div id="bus">
          {loading === true ? (
            <div role="status" className="max-w-sm animate-pulse">
              <div className="mb-4 h-2.5 rounded-full bg-gray-200"></div>
            </div>
          ) : (
            renderRoutes(
              (id) =>
                id === 901 ||
                (id >= 1 && id <= 16) ||
                (id >= 20 && id <= 57) ||
                (id >= 64 && id !== 69 && id <= 90) ||
                (id >= 454 && id <= 469),
            )
          )}
        </div>
      </center>
      <p>
        <FontAwesomeIcon icon={faFire} /> Spécial
      </p>
      <div id="autre" className="ml-3">
        {loading === true ? (
          <div role="status" className="max-w-sm animate-pulse">
            <div className="mb-4 h-2.5 rounded-full bg-gray-200"></div>
          </div>
        ) : (
          renderRoutes(
            (id) =>
              id === 18 ||
              id === 19 ||
              (id >= 159 && id <= 162) ||
              id === 568 ||
              id === 9999,
          )
        )}
      </div>
      <p>
        <FontAwesomeIcon icon={faSchool} /> ScoDi
      </p>
      <center>
        <div id="scodi">
          {loading === true ? (
            <div role="status" className="max-w-sm animate-pulse">
              <div className="mb-4 h-2.5 rounded-full bg-gray-200"></div>
            </div>
          ) : (
            renderRoutes((id) => id >= 801 && id <= 864)
          )}
        </div>
      </center>
      <p>
        <FontAwesomeIcon icon={faMoon} /> Bus de Nuit
      </p>
      <center>
        <div id="nuit">
          {loading === true ? (
            <div role="status" className="max-w-sm animate-pulse">
              <div className="mb-4 h-2.5 rounded-full bg-gray-200"></div>
            </div>
          ) : (
            renderRoutes((id) => id === 58 || (id >= 501 && id <= 507))
          )}
        </div>
      </center>
      <p>
        <FontAwesomeIcon icon={faShip} /> BAT3
      </p>
      <div id="bateau" className="ml-3">
        {loading === true ? (
          <div role="status" className="max-w-sm animate-pulse">
            <div className="mb-4 h-2.5 rounded-full bg-gray-200"></div>
          </div>
        ) : (
          renderRoutes((id) => id === 69)
        )}
      </div>
    </main>
  );
};

export default LAPage;
