import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import protobuf, { Root } from "protobufjs";
import { statutVehicule } from "./deps/StatutVehicule";
import { typeVehicule } from "./deps/TypeVehicule";
import {
  MapContainer,
  TileLayer,
  Polyline,
  Marker,
  Popup,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Feature } from "geojson";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Transition,
} from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBug,
  faChevronLeft,
  faEye,
  faEyeSlash,
  faGear,
  faInfoCircle,
  faLocationDot,
  faTriangleExclamation,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { readFile, saveFile } from "./deps/OPFS";
import { routesRequete, shapesRequete, stopsRequete } from "./deps/DoNotOPFS";

// Création des variables nécessaires
type typeGTFS = {
  routes:
    | {
        id: string;
        name: string;
        name_short: string;
        terminus: object[];
      }[]
    | undefined;
  shapes: Feature<GeoJSON.Geometry, { routeColor: string; routeId: string }>[];
  stops: [
    {
      id: string[];
      lignes: string[];
      name: string;
      position: number[];
    },
  ];
  tripUpdates:
    | [
        {
          id: string;
          tripUpdate: {
            stopTimeUpdate: [
              {
                arrival?: {
                  delay: number;
                  time: string;
                };
                departure?: {
                  delay: number;
                  time: string;
                };
                scheduleRelationship: string;
                stopId: string;
                stopSequence: number;
              },
            ];
            timestamp: string;
            trip: {
              directionId: number;
              routeId: string;
              scheduleRelationship: string;
              startDate: string;
              tripId: string;
            };
            vehicle?: {
              id: string;
              label: string;
            };
          };
        },
      ]
    | undefined;
  vehicle: GeoJSON.FeatureCollection;
};
type typeUNid = {
  arretAct: string;
  direction: string;
  gid: string;
  ligne: string[];
  numArret: number;
  retard: number;
  statut: string;
  vitesse: number;
};
let NEWterminus,
  DESdonnees,
  statutVeh,
  couleurStatut,
  stopId: any,
  stopRow,
  stopName;
let Icon = new L.Icon({
  iconUrl: `./ressources/position.svg`,
  iconSize: [32, 32],
});
const Options = JSON.parse(localStorage.getItem("Options") || "null");

interface VehicleData {
  id: string;
  vehicle: {
    currentStatus: string;
    currentStopSequence: number;
    position: {
      latitude: number;
      longitude: number;
      speed: number;
    };
    stopId: string;
    timestamp: string;
    trip?: {
      directionId: number;
      routeId: string;
      tripId: string;
    };
    vehicle: {
      id: string;
      label: string;
    };
  };
}

const LaCarte = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const OPFSString = localStorage.getItem("OPFS");
  // eslint-disable-next-line
  const OPFS: {
    routes?: string;
    shapes?: string;
    stops?: string;
  } = OPFSString ? JSON.parse(OPFSString) : {};
  const [maj, setMaj] = useState<true | false>(false);
  const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000; // 172800000 ms
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search).get("ligne");
  const [loading, setLoading] = useState<boolean>(true);
  const [GTFS, setGTFS] = useState<null | typeGTFS>(null);
  const [localiser, setLocaliser] = useState<[null | boolean, any]>([null, []]);
  const [alertes, setAlertes] = useState({
    TBM: !urlParams ? true : false,
    parcours: false,
    scodi: false,
    arretAct: urlParams === "BRT" ? true : false,
  });
  const [nbVehic, setNbVehic] = useState(0);
  const [show, setShow] = useState(true);
  const [erreur, setErreur] = useState<[boolean, null | any]>([false, null]);
  const [view, setView] = useState<boolean>(false);

  const popup = (UNid: Feature<GeoJSON.Geometry, typeUNid>, GTFS: typeGTFS) => {
    if (urlParams && urlParams === "BRT") {
      let color: string;
      let statut: string;
      let LEretard = Math.abs(Math.round(UNid.properties.retard / 60)) + " min";

      if (UNid.properties.statut === "TERMINUS_DEP") {
        color = "grey";
        statut = "🚏 Au terminus";
      } else {
        if (UNid.properties.retard > -60 && UNid.properties.retard < 60) {
          statut = "🟢 À l'heure";
          color = "green";
        } else if (UNid.properties.retard >= 60) {
          statut = "⚠️ En <b><u>retard</u></b> de " + LEretard;
          color = "orange";
        } else {
          statut = "⚠️ En <b><u>avance</u></b> de " + LEretard;
          color = "red";
        }
      }

      DESdonnees = { statutVeh: statut, couleurStatut: color };
    } else {
      DESdonnees = statutVehicule(UNid, GTFS.tripUpdates!, GTFS.stops);
    }
    if (!DESdonnees) {
      return;
    }

    statutVeh = DESdonnees["statutVeh"];
    couleurStatut = DESdonnees["couleurStatut"];
    NEWterminus = UNid.properties.direction;
    DESdonnees = typeVehicule(UNid.properties.gid, UNid.properties.ligne[0]);

    let popupContent: string;
    if (urlParams === "BRT") {
      popupContent = `
        <div class="popup">
          <center>
            <img 
              class="lignes mb-2 mr-0"
              src="./ressources/BRT/${UNid.properties.ligne[0]}.svg"
              alt="Logo : ${UNid.properties.ligne[1]}"
              title="Logo : ${UNid.properties.ligne[1]}"
            />
          </center>
          <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 512 512" class="inline-flex mb-1">
            <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path fill="#74C0FC" d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zm395.3 11.3l-112 112c-4.6 4.6-11.5 5.9-17.4 3.5s-9.9-8.3-9.9-14.8l0-64-96 0c-17.7 0-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32l96 0 0-64c0-6.5 3.9-12.3 9.9-14.8s12.9-1.1 17.4 3.5l112 112c6.2 6.2 6.2 16.4 0 22.6z"/>
          </svg>
          <span>${NEWterminus}</span>
      `;
    } else {
      popupContent = `
        <div class="popup">
          <center>
            <img 
              class="lignes mb-2 mr-0"
              src="https://carto-maas.infotbm.com/assets/images/lines/${UNid.properties.ligne[0]}.svg"
              alt="Logo : ${UNid.properties.ligne[1]}"
              title="Logo : ${UNid.properties.ligne[1]}"
            />
          </center>
          <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 512 512" class="inline-flex mb-1">
            <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path fill="#74C0FC" d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zm395.3 11.3l-112 112c-4.6 4.6-11.5 5.9-17.4 3.5s-9.9-8.3-9.9-14.8l0-64-96 0c-17.7 0-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32l96 0 0-64c0-6.5 3.9-12.3 9.9-14.8s12.9-1.1 17.4 3.5l112 112c6.2 6.2 6.2 16.4 0 22.6z"/>
          </svg>
          <span>${NEWterminus}</span>
      `;
    }

    popupContent += `
      <p style="color: ${couleurStatut}; margin: 0;">
        ${statutVeh}
      </p><br />
      ${DESdonnees["type"]}
    `;

    // Personnalisation de l'affichage de la popup en fonction de ce que veut voir l'utilisateur
    if (Options["modele"] === true) {
      popupContent += `
        <br />
        <u>Modèle</u> ➡️ ${DESdonnees["modele"]}
      `;
    }
    if (Options["exploitant"] === true) {
      popupContent += `
        <br />
        <u>Exploitant</u> ➡️ ${DESdonnees["exploitant"]}
      `;
    }
    if (
      Options["vitesse"] === true &&
      UNid.properties?.ligne[1].split(" ")[0] !== "SCODI" &&
      !popupContent.includes("‼️")
    ) {
      popupContent += `
        <br />
        <u>Vitesse</u> ➡️ ${urlParams === "BRT" ? UNid.properties?.vitesse : Math.floor(UNid.properties?.vitesse * 3.6)} km/h
      `;
    }
    if (Options["arret"] === true && !popupContent.includes("‼️")) {
      if (urlParams !== "BRT") {
        stopId = UNid.properties?.arretAct;
        stopRow = GTFS.stops.find((row) => row.id.includes(stopId));
        stopName = stopRow ? stopRow.name : "❓ Inconnu";
        if (UNid.properties?.statut === "IN_TRANSIT_TO") {
          popupContent += `
          <br />
          <u>Prochain arrêt</u> ➡️ ${stopName}
        `;
        } else {
          popupContent += `
          <br />
          <u>Arrêt en cours</u> ➡️ ${stopName}
        `;
        }
      }
    }

    popupContent += "</div>";

    return popupContent;
  };

  const requetes = useCallback(async () => {
    let routes: {
      id: string;
      name: string;
      name_short: string;
      terminus: object[];
    }[] = [];
    let shapes;
    let stops;
    try {
      if (!isSafari && navigator.storage && Object.keys(OPFS).length !== 0) {
        const mtn = Date.now().toString();
        if (urlParams !== "") {
          // routes.json
          if (
            OPFS.routes &&
            Date.now() - parseInt(OPFS.routes, 10) < twoDaysInMilliseconds
          ) {
            routes = await readFile("routes.json");
          } else {
            setMaj(true);
            const requete1 = await routesRequete();
            if (!requete1) {
              setErreur([true, "TypeError: Failed to fetch"]);
            } else {
              await saveFile("routes.json", requete1);
              OPFS.routes = mtn;
              localStorage.setItem("OPFS", JSON.stringify(OPFS));
              routes = requete1;
            }
          }
          if (urlParams) {
            // shapes.json
            if (
              OPFS.shapes &&
              Date.now() - parseInt(OPFS.shapes, 10) < twoDaysInMilliseconds
            ) {
              shapes = await readFile("shapes.json");
            } else {
              setMaj(true);
              const requete3 = await shapesRequete();
              if (!requete3) {
                setErreur([true, "TypeError: Failed to fetch"]);
              } else {
                await saveFile("shapes.json", requete3);
                OPFS.shapes = mtn;
                localStorage.setItem("OPFS", JSON.stringify(OPFS));
                shapes = requete3;
              }
            }
          }
        }

        // stops.json
        if (
          OPFS.stops &&
          Date.now() - parseInt(OPFS.stops, 10) < twoDaysInMilliseconds
        ) {
          stops = await readFile("stops.json");
        } else {
          setMaj(true);
          const requete2 = await stopsRequete();
          if (!requete2) {
            setErreur([true, "TypeError: Failed to fetch"]);
          } else {
            await saveFile("stops.json", requete2);
            OPFS.stops = mtn;
            localStorage.setItem("OPFS", JSON.stringify(OPFS));
            stops = requete2;
          }
        }

        if (maj) {
          window.location.reload();
        }
      } else {
        const requete1 = await stopsRequete();
        const requete2 = await routesRequete();
        const requete3 = await shapesRequete();

        if (!requete1) {
          setErreur([true, "TypeError: Failed to fetch"]);
        } else {
          stops = requete1;
        }

        if (!requete2) {
          setErreur([true, "TypeError: Failed to fetch"]);
        } else {
          routes = requete2;
        }

        if (!requete3) {
          setErreur([true, "TypeError: Failed to fetch"]);
        } else {
          shapes = requete3;
        }
      }
    } catch (ERRfatal) {
      console.error(ERRfatal);
      setErreur([true, ERRfatal]);
    }

    const vehicles: GeoJSON.FeatureCollection = {
      type: "FeatureCollection",
      features: [],
    };
    // GTFS-RT Vehicles
    if (urlParams !== "BRT") {
      try {
        const response = await fetch(
          "https://bdx.mecatran.com/utw/ws/gtfsfeed/vehicles/bordeaux?apiKey=opendata-bordeaux-metropole-flux-gtfs-rt",
        );
        const data = await response.arrayBuffer();
        const root: Root = await new Promise((resolve, reject) => {
          protobuf.load(
            "./ressources/GTFS/fichier.proto",
            (ERRfatal: any, root: any) => {
              if (ERRfatal) {
                reject(ERRfatal);
              } else {
                resolve(root);
              }
            },
          );
        });
        const FeedMessage = root.lookupType("transit_realtime.FeedMessage");
        const message = FeedMessage.decode(new Uint8Array(data));
        const pro = JSON.stringify(message, null, 2);
        JSON.parse(pro).entity.forEach((element: VehicleData) => {
          if (
            !urlParams ||
            (element.vehicle.trip && element.vehicle.trip.routeId === urlParams)
          ) {
            const data: GeoJSON.Feature = {
              geometry: {
                type: "Point",
                coordinates: [
                  element.vehicle.position.latitude,
                  element.vehicle.position.longitude,
                ],
              },
              id: element.id.split("RTVP:T:")[1],
              properties: {
                arretAct: element.vehicle.stopId,
                direction: element.vehicle.vehicle.label,
                gid: element.vehicle.vehicle.id.includes(":")
                  ? element.vehicle.vehicle.id.split(":")[1]
                  : element.vehicle.vehicle.id,
                ligne: routes
                  .filter(
                    (LAroute) => element.vehicle.trip?.routeId === LAroute.id,
                  )
                  .map((LAroute) => {
                    return [LAroute.name_short, LAroute.name];
                  })[0],
                numArret: element.vehicle.currentStopSequence,
                statut: element.vehicle.currentStatus,
                vitesse: element.vehicle.position.speed,
              },
              type: "Feature",
            };
            vehicles.features.push(data);
          }
        });
      } catch (ERRfatal) {
        console.error(ERRfatal);
        setErreur([true, ERRfatal]);
      }

      if (
        vehicles.features.filter(
          (item) => item.properties?.ligne[1].split(" ")[0] === "SCODI",
        ).length > 0
      ) {
        alertes.scodi = true;
        setAlertes(alertes);
      }

      let tripUpdates = "[]";
      // GTFS-RT TripUpdate
      try {
        const response = await fetch(
          "https://bdx.mecatran.com/utw/ws/gtfsfeed/realtime/bordeaux?apiKey=opendata-bordeaux-metropole-flux-gtfs-rt",
        );
        const data = await response.arrayBuffer();
        const root: Root = await new Promise((resolve, reject) => {
          protobuf.load(
            "./ressources/GTFS/fichier.proto",
            (ERRfatal: any, root: any) => {
              if (ERRfatal) {
                reject(ERRfatal);
              } else {
                resolve(root);
              }
            },
          );
        });
        const FeedMessage = root.lookupType("transit_realtime.FeedMessage");
        const message = FeedMessage.decode(new Uint8Array(data));
        tripUpdates = JSON.stringify(message, null, 2);
      } catch (ERRfatal) {
        console.error(ERRfatal);
        setErreur([true, ERRfatal]);
      }

      if (urlParams) {
        shapes = shapes.filter(
          (element: { properties: { routeId: string } }) =>
            urlParams === "BRT"
              ? parseInt(element.properties.routeId) >= 123 &&
                parseInt(element.properties.routeId) <= 198
              : element.properties.routeId === urlParams,
        );
      }
      setGTFS({
        routes: routes,
        shapes: shapes,
        stops: stops,
        tripUpdates: JSON.parse(tripUpdates).entity,
        vehicle: vehicles,
      });
    } else {
      try {
        const response = await fetch(
          `https://data.bordeaux-metropole.fr/geojson?key=258BILMNYZ&typename=sv_vehic_p&attributes=["geom","gid","retard","arret","vitesse","vehicule","statut","terminus","rs_sv_arret_p_actu","rs_sv_arret_p_suiv","rs_sv_cours_a","rs_sv_ligne_a"]`,
        );
        const data: GeoJSON.FeatureCollection = await response.json();
        const LESshapes: object[] = [];
        for (let i = 123; i <= 198; i++) {
          if (
            (i >= 154 && i <= 157) ||
            (i >= 176 && i <= 178) ||
            (i >= 187 && i <= 191)
          ) {
            continue;
          }
          if (
            data.features.filter(
              (element: GeoJSON.Feature) =>
                element.properties?.rs_sv_ligne_a === i,
            ).length > 0
          ) {
            const element = data.features.filter(
              (element: GeoJSON.Feature) =>
                element.properties?.rs_sv_ligne_a === i,
            );
            element.forEach((Lelement) => {
              if (Lelement.geometry.type === "Point") {
                const qqch =
                  i >= 123 && i <= 150
                    ? ["Bus A", "Bus Relais A"]
                    : i === 151 || (i >= 158 && i <= 175)
                      ? ["Bus B", "Bus Relais B"]
                      : i === 152 || (i >= 179 && i <= 186)
                        ? ["Bus C", "Bus Relais C"]
                        : ["Bus D", "Bus Relais D"];

                const data: GeoJSON.Feature = {
                  geometry: {
                    type: "Point",
                    coordinates: [
                      Lelement.geometry.coordinates[1],
                      Lelement.geometry.coordinates[0],
                    ],
                  },
                  id: Lelement.properties?.gid,
                  properties: {
                    arretAct:
                      Lelement.properties?.arret === true
                        ? Lelement.properties?.rs_sv_arret_p_actu
                        : Lelement.properties?.rs_sv_arret_p_suiv,
                    direction: Lelement.properties?.terminus,
                    gid: Lelement.properties?.gid,
                    ligne: qqch,
                    retard: Lelement.properties?.retard,
                    statut: Lelement.properties?.statut,
                    vitesse: Lelement.properties?.vitesse,
                  },
                  type: "Feature",
                };
                vehicles.features.push(data);
              }
            });

            try {
              const response = await fetch(
                `https://data.bordeaux-metropole.fr/geojson?key=258BILMNYZ&typename=SV_CHEM_L&attributes=["geom"]&filter={"rs_sv_ligne_a":${i},"principal":1}`,
              );
              const data: GeoJSON.FeatureCollection = await response.json();
              data.features.forEach((element) => {
                const qqch =
                  i >= 123 && i <= 150
                    ? "rgb(131,31,130)"
                    : i === 151 || (i >= 158 && i <= 175)
                      ? "rgb(229,0,64)"
                      : i === 152 || (i >= 179 && i <= 186)
                        ? "rgb(211,80,152)"
                        : "rgb(146,98,163)";

                if (element.geometry.type === "MultiLineString") {
                  element.geometry.coordinates.forEach((element) => {
                    const data: GeoJSON.Feature = {
                      geometry: {
                        coordinates: element.map((element) => {
                          return [element[1], element[0]];
                        }),
                        type: "LineString",
                      },
                      properties: {
                        routeId: i,
                        routeColor: qqch,
                      },
                      type: "Feature",
                    };
                    LESshapes.push(data);
                  });
                } else if (element.geometry.type === "LineString") {
                  const data: GeoJSON.Feature = {
                    geometry: {
                      coordinates: element.geometry.coordinates.map(
                        (element) => {
                          return [element[1], element[0]];
                        },
                      ),
                      type: "LineString",
                    },
                    properties: {
                      routeId: i,
                      routeColor: qqch,
                    },
                    type: "Feature",
                  };
                  LESshapes.push(data);
                }
              });
            } catch (ERRfatal) {
              console.error(ERRfatal);
              setErreur([true, ERRfatal]);
            }
          }
          shapes = LESshapes;
        }
      } catch (ERRfatal) {
        console.error(ERRfatal);
        setErreur([true, ERRfatal]);
      }

      setGTFS({
        routes: undefined,
        shapes: shapes,
        stops: stops,
        tripUpdates: undefined,
        vehicle: vehicles,
      });
    }
    setNbVehic(vehicles.features.length);

    if (
      urlParams &&
      shapes.filter((element: { properties: { routeId: string } }) =>
        urlParams === "BRT"
          ? parseInt(element.properties.routeId) >= 123 &&
            parseInt(element.properties.routeId) <= 198
          : element.properties.routeId === urlParams,
      ).length === 0
    ) {
      alertes.parcours = true;
      setAlertes(alertes);
    }
    setLoading(false);
  }, [
    isSafari,
    urlParams,
    setErreur,
    alertes,
    setAlertes,
    setGTFS,
    setNbVehic,
    setLoading,
    OPFS,
    maj,
    twoDaysInMilliseconds,
  ]);

  const fetchdata = useCallback(async () => {
    if (GTFS) {
      const vehicles: GeoJSON.FeatureCollection = {
        type: "FeatureCollection",
        features: [],
      };
      if (urlParams !== "BRT") {
        try {
          const response = await fetch(
            "https://bdx.mecatran.com/utw/ws/gtfsfeed/vehicles/bordeaux?apiKey=opendata-bordeaux-metropole-flux-gtfs-rt",
          );
          const data = await response.arrayBuffer();
          const root: Root = await new Promise((resolve, reject) => {
            protobuf.load(
              "./ressources/GTFS/fichier.proto",
              (ERRfatal: any, root: any) => {
                if (ERRfatal) {
                  reject(ERRfatal);
                } else {
                  resolve(root);
                }
              },
            );
          });
          const FeedMessage = root.lookupType("transit_realtime.FeedMessage");
          const message = FeedMessage.decode(new Uint8Array(data));
          const pro = JSON.stringify(message, null, 2);
          JSON.parse(pro).entity.forEach((element: VehicleData) => {
            if (
              !urlParams ||
              (element.vehicle.trip &&
                element.vehicle.trip.routeId === urlParams)
            ) {
              const data: GeoJSON.Feature = {
                geometry: {
                  type: "Point",
                  coordinates: [
                    element.vehicle.position.latitude,
                    element.vehicle.position.longitude,
                  ],
                },
                id: element.id.split("RTVP:T:")[1],
                properties: {
                  arretAct: element.vehicle.stopId,
                  direction: element.vehicle.vehicle.label,
                  gid: element.vehicle.vehicle.id.includes(":")
                    ? element.vehicle.vehicle.id.split(":")[1]
                    : element.vehicle.vehicle.id,
                  ligne: GTFS.routes!.filter(
                    (LAroute) => element.vehicle.trip?.routeId === LAroute.id,
                  ).map((LAroute) => {
                    return [LAroute.name_short, LAroute.name];
                  })[0],
                  numArret: element.vehicle.currentStopSequence,
                  statut: element.vehicle.currentStatus,
                  vitesse: element.vehicle.position.speed,
                },
                type: "Feature",
              };
              vehicles.features.push(data);
            }
          });
        } catch (ERRfatal) {
          console.error(ERRfatal);
          setErreur([true, ERRfatal]);
        }

        if (
          vehicles.features.filter(
            (item) => item.properties?.ligne[1].split("")[0] === "SCODI",
          ).length > 0
        ) {
          alertes.scodi = true;
          setAlertes(alertes);
        }

        let TripUpdates:
          | [
              {
                id: string;
                tripUpdate: {
                  stopTimeUpdate: [
                    {
                      arrival?: {
                        delay: number;
                        time: string;
                      };
                      departure?: {
                        delay: number;
                        time: string;
                      };
                      scheduleRelationship: string;
                      stopId: string;
                      stopSequence: number;
                    },
                  ];
                  timestamp: string;
                  trip: {
                    directionId: number;
                    routeId: string;
                    scheduleRelationship: string;
                    startDate: string;
                    tripId: string;
                  };
                };
              },
            ]
          | undefined;
        // GTFS-RT TripUpdate
        try {
          const response = await fetch(
            "https://bdx.mecatran.com/utw/ws/gtfsfeed/realtime/bordeaux?apiKey=opendata-bordeaux-metropole-flux-gtfs-rt",
          );
          const data = await response.arrayBuffer();
          const root: Root = await new Promise((resolve, reject) => {
            protobuf.load(
              "./ressources/GTFS/fichier.proto",
              (ERRfatal: any, root: any) => {
                if (ERRfatal) {
                  reject(ERRfatal);
                } else {
                  resolve(root);
                }
              },
            );
          });
          const FeedMessage = root.lookupType("transit_realtime.FeedMessage");
          const message = FeedMessage.decode(new Uint8Array(data));
          let pro = JSON.stringify(message, null, 2);
          TripUpdates = JSON.parse(pro).entity;
        } catch (ERRfatal) {
          console.error(ERRfatal);
          setErreur([true, ERRfatal]);
        }

        setGTFS((prev) => {
          if (prev) {
            let element = { ...prev };
            element.tripUpdates = TripUpdates;
            return element;
          } else {
            return prev;
          }
        });
      } else {
        try {
          const response = await fetch(
            `https://data.bordeaux-metropole.fr/geojson?key=258BILMNYZ&typename=sv_vehic_p&attributes=["geom","gid","retard","arret","vitesse","vehicule","statut","terminus","rs_sv_arret_p_actu","rs_sv_arret_p_suiv","rs_sv_cours_a","rs_sv_ligne_a"]`,
          );
          const data: GeoJSON.FeatureCollection = await response.json();
          for (let i = 123; i <= 198; i++) {
            if (
              (i >= 154 && i <= 157) ||
              (i >= 176 && i <= 178) ||
              (i >= 187 && i <= 191)
            ) {
              continue;
            }
            if (
              data.features.filter(
                (element: GeoJSON.Feature) =>
                  element.properties?.rs_sv_ligne_a === i,
              ).length > 0
            ) {
              const element = data.features.filter(
                (element: GeoJSON.Feature) =>
                  element.properties?.rs_sv_ligne_a === i,
              );
              element.forEach((Lelement) => {
                if (Lelement.geometry.type === "Point") {
                  const qqch =
                    i >= 123 && i <= 150
                      ? ["Bus A", "Bus Relais A"]
                      : i === 151 || (i >= 158 && i <= 175)
                        ? ["Bus B", "Bus Relais B"]
                        : i === 152 || (i >= 179 && i <= 186)
                          ? ["Bus C", "Bus Relais C"]
                          : ["Bus D", "Bus Relais D"];

                  const data: GeoJSON.Feature = {
                    geometry: {
                      type: "Point",
                      coordinates: [
                        Lelement.geometry.coordinates[1],
                        Lelement.geometry.coordinates[0],
                      ],
                    },
                    id: Lelement.properties?.gid,
                    properties: {
                      arretAct:
                        Lelement.properties?.arret === true
                          ? Lelement.properties?.rs_sv_arret_p_actu
                          : Lelement.properties?.rs_sv_arret_p_suiv,
                      direction: Lelement.properties?.terminus,
                      gid: Lelement.properties?.gid,
                      ligne: qqch,
                      retard: Lelement.properties?.retard,
                      statut: Lelement.properties?.statut,
                      vitesse: Lelement.properties?.vitesse,
                    },
                    type: "Feature",
                  };
                  vehicles.features.push(data);
                }
              });
            }
          }
        } catch (ERRfatal) {
          console.error(ERRfatal);
          setErreur([true, ERRfatal]);
        }
      }

      setNbVehic(vehicles.features.length);

      setGTFS((prev) => {
        if (prev) {
          let element = { ...prev };
          element.vehicle = vehicles;
          return element;
        } else {
          return prev;
        }
      });
    }
  }, [GTFS, alertes, urlParams, setAlertes, setGTFS, setNbVehic]);

  useEffect(() => {
    if (!GTFS) {
      requetes();
    }
    const intervalId = setInterval(fetchdata, 30000); // Set up interval to fetch data every 30 seconds
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [GTFS, requetes, fetchdata]);

  if (loading) {
    return (
      <div className="text-center">
        <div role="status">
          <svg
            aria-hidden="true"
            className="inline h-8 w-8 animate-spin fill-yellow-400 text-gray-200"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (localiser[0] === false) {
    const handleLocation = (position: {
      coords: { latitude: any; longitude: any };
    }) => {
      const coordinates = [position.coords.latitude, position.coords.longitude];
      setLocaliser([true, coordinates]);
    };

    const handleLocationError = () => {
      return setErreur([
        true,
        "Verifie si la localisation a bien été autorisée dans les paramètres de ton navigateur, puis actualise la page",
      ]);
    };

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        handleLocation,
        handleLocationError,
      );
    } else {
      /* Le navigateur n'est pas compatible */
      alert("Géolocalisation indisponible sur ce navigateur");
      setLocaliser([null, []]);
    }
  }

  return (
    <main>
      {erreur[0] && (
        <Dialog open={true} onClose={() => true} className="relative z-10">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-300 sm:mx-0 sm:h-10 sm:w-10">
                      <FontAwesomeIcon icon={faBug} />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Erreur
                      </DialogTitle>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Une erreur{" "}
                          <b>
                            {String(erreur[1]).includes("fetch")
                              ? "lors d'une requête"
                              : "inconnue"}
                          </b>{" "}
                          est survenue.{" "}
                          {String(erreur[1]).includes("fetch")
                            ? "Vérifie ta connexion Internet et actualise la page"
                            : "Patiente quelques instants puis actualise la page"}
                          <br />
                          <span
                            className="flex justify-start text-blue-500"
                            onClick={() => setView(!view)}
                          >
                            {view ? (
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                className="mr-1"
                              />
                            ) : (
                              <FontAwesomeIcon icon={faEye} className="mr-1" />
                            )}
                            {view
                              ? "Cacher les détails"
                              : "Afficher les détails"}
                          </span>
                          {view && (
                            <span className="flex justify-start text-sm italic text-gray-500">
                              {String(erreur[1])}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <Link to="/LaCarte" onClick={() => window.location.reload()}>
                    {" "}
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-300 sm:ml-3 sm:w-auto"
                    >
                      Actualiser la page
                    </button>
                  </Link>
                  {!String(erreur[1]).includes("localisation") && (
                    <Link to="/Accueil">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      >
                        Retour à l'accueil
                      </button>
                    </Link>
                  )}
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      )}
      {alertes.TBM && nbVehic !== 0 && (
        <div
          aria-live="assertive"
          className="pointer-events-none inset-0 flex items-end px-4 sm:items-start"
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            <Transition
              show={show}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm text-gray-500">
                        Pour afficher <u>le parcours d'une ligne spécifique</u>,
                        il faut <b>la choisir</b> plutôt que d'afficher le
                        réseau TBM
                      </p>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      )}
      {alertes.parcours && nbVehic !== 0 && (
        <div
          aria-live="assertive"
          className="pointer-events-none inset-0 flex items-end px-4 sm:items-start"
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            <Transition
              show={show}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <FontAwesomeIcon icon={faTriangleExclamation} />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm text-gray-500">
                        <b>Le parcours de cette ligne n'est pas disponible !</b>
                        <br />
                        Il sera sûrement disponible dans{" "}
                        <u>les prochains jours</u> à venir
                      </p>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      )}
      {alertes.scodi && nbVehic !== 0 && (
        <div
          aria-live="assertive"
          className="pointer-events-none inset-0 flex items-end px-4 sm:items-start"
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            <Transition
              show={show}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm text-gray-500">
                        Des ScoDi ont été détectés !<br />
                        Pour rappel, les ScoDi ne prennent pas en charge{" "}
                        <b>la vitesse actuelle</b> et les modèles et exploitants
                        ne sont pas tous renseignés
                      </p>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      )}
      {alertes.arretAct && nbVehic !== 0 && (
        <div
          aria-live="assertive"
          className="pointer-events-none inset-0 flex items-end px-4 sm:items-start"
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            <Transition
              show={show}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm text-gray-500">
                        L'arrêt actuel/Prochain arrêt des véhicules en service
                        restera caché
                        <br />
                        <u>Cause :</u> Fournit de fausses informations (
                        <i>Exemple: Ambarès Centre au lieu de Unitec</i>)
                      </p>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      )}
      <Link to="/Carte">
        <button
          type="button"
          className="mb-2 me-2 rounded-full bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
          title="Choisir une autre ligne"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </Link>
      <br />
      <center>
        <button
          type="button"
          className="mb-2 me-2 rounded-full bg-yellow-400 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300"
          onClick={() => setLocaliser([false, []])}
          title="Te localiser"
        >
          <FontAwesomeIcon icon={faLocationDot} />
        </button>
        <Link to="/Parametres">
          <button
            type="button"
            className="mb-2 me-2 rounded-full bg-gray-800 px-5 py-2.5 text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300"
            title="Paramètres"
          >
            <FontAwesomeIcon icon={faGear} />
          </button>
        </Link>
        <p>
          {nbVehic === 0 ? (
            <span className="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
              <span className="me-1 h-2 w-2 rounded-full bg-red-500"></span>
              Hors service
            </span>
          ) : nbVehic === 1 ? (
            <>
              <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                <span className="me-1 h-2 w-2 rounded-full bg-green-500"></span>
                En service
              </span>{" "}
              <span className="font-bold">1 véhicule</span>
            </>
          ) : (
            <>
              <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                <span className="me-1 h-2 w-2 rounded-full bg-green-500"></span>
                En service
              </span>{" "}
              <span className="font-bold">{nbVehic} véhicules</span>
            </>
          )}
        </p>
      </center>
      {GTFS !== null && !loading && (
        <MapContainer
          id="map"
          center={[44.85, -0.58]}
          zoom={11}
          minZoom={5}
          maxZoom={19}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {GTFS.vehicle &&
            GTFS.vehicle.features.map((element: any) => {
              const content = popup(element, GTFS);
              return (
                <Marker
                  key={element.properties?.gid}
                  position={element.geometry.coordinates}
                  icon={
                    new L.Icon({
                      iconUrl:
                        urlParams === "BRT"
                          ? `./ressources/BRT/${element.properties.ligne[0]}.svg`
                          : `https://carto-maas.infotbm.com/assets/images/lines/${element.properties?.ligne[0]}.svg`,
                      iconSize: [32, 32],
                    })
                  }
                  alt={`Logo : ${element.properties.ligne[1]}`}
                  title={`Logo : ${element.properties.ligne[1]}`}
                >
                  <Popup>
                    <div dangerouslySetInnerHTML={{ __html: content! }}></div>
                  </Popup>
                </Marker>
              );
            })}
          {urlParams &&
            GTFS.shapes.map((element: any) => {
              return (
                <Polyline
                  key={
                    element.properties.routeId +
                    JSON.stringify(element.geometry.coordinates)
                  }
                  positions={element.geometry.coordinates}
                  color={element.properties.routeColor}
                  weight={5}
                />
              );
            })}
          {localiser[0] === true && (
            <Marker position={localiser[1]} icon={Icon}>
              <Popup>
                Ta Position à {new Date().getHours()}:
                {new Date().getMinutes() <= 9
                  ? "0" + new Date().getMinutes()
                  : new Date().getMinutes()}
              </Popup>
            </Marker>
          )}
        </MapContainer>
      )}
    </main>
  );
};

export default LaCarte;
