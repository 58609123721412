import { useState } from "react";
import { Dialog, DialogPanel, PopoverGroup } from "@headlessui/react";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import {
  faBars,
  faClock,
  faGear,
  faHouse,
  faMap,
  faTriangleExclamation,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LANav = () => {
  const location = useLocation();
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
      },
    }),
  };

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-gray-100">
      <nav
        aria-label="Global"
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
      >
        <div className="flex lg:flex-1">
          <img
            alt="Logo : T'es Bon pour Marcher (TBpM)"
            title="Logo : T'es Bon pour Marcher (TBpM)"
            src="./favicon.png"
            className="h-8 w-auto"
          />
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
        <PopoverGroup className="hidden lg:flex lg:gap-x-12">
          <Link
            key={"Accueil"}
            to="/Accueil"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => {
              if (location.pathname === "/Parametres") {
                setTimeout(() => {
                  window.location.reload();
                }, 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faHouse} />
          </Link>
          <Link
            key={"Carte"}
            to="/Carte"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => {
              if (location.pathname === "/Parametres") {
                setTimeout(() => {
                  window.location.reload();
                }, 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faMap} />
          </Link>
          <Link
            key={"Info Trafic"}
            to="/InfoTrafic"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => {
              if (location.pathname === "/Parametres") {
                setTimeout(() => {
                  window.location.reload();
                }, 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </Link>
          <Link
            key={"Prochains Passages"}
            to="/ProchainsPassages"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => {
              if (location.pathname === "/Parametres") {
                setTimeout(() => {
                  window.location.reload();
                }, 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faClock} />
          </Link>
          <Link
            key={"Paramètres"}
            to="/Parametres"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => {
              if (location.pathname === "/Parametres") {
                setTimeout(() => {
                  window.location.reload();
                }, 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faGear} />
          </Link>
        </PopoverGroup>
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-end">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="py-6">
                <h1 className="flex justify-center text-5xl font-extrabold">
                  {"TBpM".split("").map((char, index) => (
                    <motion.span
                      key={index}
                      custom={index}
                      initial="hidden"
                      animate="visible"
                      variants={variants}
                    >
                      {char}
                    </motion.span>
                  ))}
                </h1>
                <br />
                <Link
                  key={"Accueil"}
                  to="/Accueil"
                  className="block text-lg font-semibold leading-6 text-gray-900"
                  onClick={() => {
                    if (location.pathname === "/Parametres") {
                      setTimeout(() => {
                        window.location.reload();
                      }, 1);
                    }
                    setMobileMenuOpen(false);
                  }}
                >
                  <FontAwesomeIcon icon={faHouse} /> Accueil
                </Link>
                <Link
                  key={"Carte"}
                  to="/Carte"
                  className="mt-8 block text-lg font-semibold leading-6 text-gray-900"
                  onClick={() => {
                    if (location.pathname === "/Parametres") {
                      setTimeout(() => {
                        window.location.reload();
                      }, 1);
                    }
                    setMobileMenuOpen(false);
                  }}
                >
                  <FontAwesomeIcon icon={faMap} /> Carte
                </Link>
                <Link
                  key={"Info Trafic"}
                  to="/InfoTrafic"
                  className="mt-8 block text-lg font-semibold leading-6 text-gray-900"
                  onClick={() => {
                    if (location.pathname === "/Parametres") {
                      setTimeout(() => {
                        window.location.reload();
                      }, 1);
                    }
                    setMobileMenuOpen(false);
                  }}
                >
                  <FontAwesomeIcon icon={faTriangleExclamation} /> Info Trafic
                </Link>
                <Link
                  key={"Prochains Passages"}
                  to="/ProchainsPassages"
                  className="mt-8 block text-lg font-semibold leading-6 text-gray-900"
                  onClick={() => {
                    if (location.pathname === "/Parametres") {
                      setTimeout(() => {
                        window.location.reload();
                      }, 1);
                    }
                    setMobileMenuOpen(false);
                  }}
                >
                  <FontAwesomeIcon icon={faClock} /> Prochains Passages
                </Link>
                <Link
                  key={"Paramètres"}
                  to="/Parametres"
                  className="mt-8 block text-lg font-semibold leading-6 text-gray-900"
                  onClick={() => {
                    if (location.pathname === "/Parametres") {
                      setTimeout(() => {
                        window.location.reload();
                      }, 1);
                    }
                    setMobileMenuOpen(false);
                  }}
                >
                  <FontAwesomeIcon icon={faGear} /> Paramètres
                </Link>
              </div>
              <p className="absolute bottom-0 mb-3 italic">Version 8.1</p>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
};

export default LANav;
