import {
  faTrashCan,
  faXmark,
  faCircleRight,
  faBug,
  faEye,
  faEyeSlash,
  faCircleExclamation,
  faGripVertical,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Switch,
} from "@headlessui/react";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sortable from "sortablejs";
import { deleteFile, readFile, saveFile } from "./deps/OPFS";
import { routesRequete, shapesRequete, stopsRequete } from "./deps/DoNotOPFS";

// Section Options
interface OptionsType {
  modele: boolean;
  exploitant: boolean;
  vitesse: boolean;
  arret: boolean;
}

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const OptionsString = localStorage.getItem("Options");
const Options: OptionsType = JSON.parse(OptionsString || "null");

// Section Lignes
const LignesFavoritesString = localStorage.getItem("LignesFavorites");
const LignesFavorites: string[][] = LignesFavoritesString
  ? JSON.parse(LignesFavoritesString)
  : [];

// Sections Arrets
const ArretsFavorisString = localStorage.getItem("ArretsFavoris");
const ArretsFavoris: Record<string, string[]> = ArretsFavorisString
  ? JSON.parse(ArretsFavorisString)
  : {};

// Sections MAJ
const OPFSString =
  !isSafari && navigator.storage && localStorage.getItem("OPFS");
const OPFS: {
  routes: string;
  shapes: string;
  stops: string;
} = OPFSString ? JSON.parse(OPFSString) : {};
const formatDate = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Les mois commencent à 0
  const year = date.getFullYear().toString();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  return `${day}/${month}/${year} à ${hours}:${minutes}:${seconds}`;
};
const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000; // 172800000 ms

const people = [
  {
    name: "shapes.json",
    description: "Stocke le parcours de chaque ligne du réseau TBM",
    maj: OPFS && formatDate(new Date(parseInt(OPFS.shapes, 10))),
    necessaire:
      OPFS && Date.now() - parseInt(OPFS.shapes, 10) > twoDaysInMilliseconds
        ? true
        : false,
  },
  {
    name: "stops.json",
    description: "Stocke tous les arrêts existants du réseau TBM",
    maj: OPFS && formatDate(new Date(parseInt(OPFS.stops, 10))),
    necessaire:
      OPFS && Date.now() - parseInt(OPFS.stops, 10) > twoDaysInMilliseconds
        ? true
        : false,
  },
  {
    name: "routes.json",
    description: "Stocke toutes les lignes existantes sur le réseau TBM",
    maj: OPFS && formatDate(new Date(parseInt(OPFS.routes, 10))),
    necessaire:
      OPFS && Date.now() - parseInt(OPFS.routes, 10) > twoDaysInMilliseconds
        ? true
        : false,
  },
];

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

const LAPage = () => {
  const navigate = useNavigate();
  const [erreur, setErreur] = useState<[boolean, null | any]>([false, null]);
  const [view, setView] = useState<boolean>(false);
  const [options, setOptions] = useState<OptionsType>(Options);
  const [supp, setSupp] = useState<[boolean, string, string]>([false, "", ""]);
  const [lignesFavorites, setLignesFavorites] =
    useState<string[][]>(LignesFavorites);
  const [arretsFavoris, setArretsFavoris] =
    useState<Record<string, string[]>>(ArretsFavoris);
  const lignesFavoritesRef = useRef<HTMLUListElement>(null);
  const arretsFavorisRef = useRef<HTMLUListElement>(null);
  const [maj, setMaj] = useState<[boolean, string]>([false, ""]);

  useEffect(() => {
    localStorage.setItem("Options", JSON.stringify(options));
  }, [options]);

  useEffect(() => {
    localStorage.setItem("LignesFavorites", JSON.stringify(lignesFavorites));
  }, [lignesFavorites]);

  useEffect(() => {
    localStorage.setItem("ArretsFavoris", JSON.stringify(arretsFavoris));
  }, [arretsFavoris]);

  useEffect(() => {
    // Gestion de lignesFavorites
    let sortableLignes: Sortable; // Initialisation à null
    if (lignesFavoritesRef.current && lignesFavorites.length > 0) {
      // Détruit l'instance Sortable s'il existe déjà
      if (sortableLignes!) {
        sortableLignes.destroy();
      }
      // Crée une nouvelle instance de Sortable
      sortableLignes = Sortable.create(lignesFavoritesRef.current, {
        onEnd: () => {
          const nouvelOrdre = Array.from(
            lignesFavoritesRef.current!.children,
          ).map((item) => {
            const imgElement = item.querySelector(
              "img.lignes",
            ) as HTMLImageElement;
            const src = imgElement.src;
            const id = decodeURIComponent(
              src.split("/").pop()?.split(".")[0] || "",
            );
            // Trouve l'élément correspondant dans lignesFavorites
            const element = lignesFavorites.find((ligne) => ligne[1] === id);
            return element;
          });
          setLignesFavorites(nouvelOrdre as string[][]);
        },
      });
    }

    // Cleanup quand le composant est démonté
    return () => {
      if (sortableLignes) {
        sortableLignes.destroy();
      }
    };
  }, [lignesFavorites]);

  useEffect(() => {
    // Gestion de arretsFavoris
    let sortableArrets: Sortable;
    if (arretsFavorisRef.current && Object.keys(arretsFavoris).length > 0) {
      // Détruit l'instance Sortable s'il existe déjà
      if (sortableArrets!) {
        sortableArrets.destroy();
      }
      // Crée une nouvelle instance de Sortable
      sortableArrets = Sortable.create(arretsFavorisRef.current, {
        onEnd: () => {
          const nouvelOrdre = Array.from(
            arretsFavorisRef.current!.children,
          ).map((item) => item.getAttribute("data-arret") || "");

          const nouvelArretsFavoris = nouvelOrdre.reduce(
            (acc, arretName) => {
              if (arretsFavoris[arretName]) {
                acc[arretName] = arretsFavoris[arretName];
              }
              return acc;
            },
            {} as Record<string, string[]>,
          );

          setArretsFavoris(nouvelArretsFavoris);
        },
      });
    }

    // Cleanup quand le composant est démonté
    return () => {
      if (sortableArrets) {
        sortableArrets.destroy();
      }
    };
  }, [arretsFavoris]);

  useEffect(() => {
    const requetes = async () => {
      const mtn = Date.now().toString();
      if (maj[1] === "stops" || maj[1] === "tlm") {
        // stops.json
        const requete10 = await stopsRequete();
        if (!requete10) {
          setErreur([true, "TypeError: Failed to fetch"]);
        } else {
          await saveFile("stops.json", requete10);
        }
        if (OPFS) {
          people[1].maj = formatDate(new Date(parseInt(mtn, 10)));
          OPFS.stops = mtn;
          localStorage.setItem("OPFS", JSON.stringify(OPFS));
        }
      }

      if (maj[1] === "routes" || maj[1] === "tlm") {
        // routes.json
        const requete2 = await routesRequete();
        if (!requete2) {
          setErreur([true, "TypeError: Failed to fetch"]);
        } else {
          await saveFile("routes.json", requete2);
        }
        if (OPFS) {
          people[2].maj = formatDate(new Date(parseInt(mtn, 10)));
          OPFS.routes = mtn;
          localStorage.setItem("OPFS", JSON.stringify(OPFS));
        }
      }

      if (maj[1] === "shapes" || maj[1] === "tlm") {
        // shapes.json
        const requete3 = await shapesRequete();
        if (!requete3) {
          setErreur([true, "TypeError: Failed to fetch"]);
        } else {
          await saveFile("shapes.json", requete3);
        }
        if (OPFS) {
          people[0].maj = formatDate(new Date(parseInt(mtn, 10)));
          OPFS.shapes = mtn;
          localStorage.setItem("OPFS", JSON.stringify(OPFS));
        }

        window.location.reload();
      }

      setMaj([false, ""]);
    };

    if (maj[0]) {
      requetes();
    }
  }, [maj]);

  const handleOptionChange = (option: keyof OptionsType, value: boolean) => {
    setOptions({
      ...options,
      [option]: value,
    });
  };

  const handleDeleteligne = (ligne: string) => {
    setLignesFavorites((prev) => {
      const newLignes = [...prev];
      const cqfs = newLignes.findIndex((item) => item[2] === ligne);
      newLignes.splice(cqfs, 1);
      return newLignes;
    });
  };

  const handleDeletearret = (Larret: string) => {
    setArretsFavoris((prev) => {
      const newArrets = { ...prev };
      delete newArrets[Larret];
      return newArrets;
    });
  };

  return (
    <main>
      {erreur[0] && (
        <Dialog
          open={true}
          onClose={() => setErreur([false, null])}
          className="relative z-10"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-300 sm:mx-0 sm:h-10 sm:w-10">
                      <FontAwesomeIcon icon={faBug} />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Erreur
                      </DialogTitle>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Une erreur{" "}
                          <b>
                            {String(erreur[1]).includes("fetch")
                              ? "lors d'une requête"
                              : "inconnue"}
                          </b>{" "}
                          est survenue.{" "}
                          {String(erreur[1]).includes("fetch")
                            ? "Vérifie ta connexion Internet et actualise la page"
                            : "Patiente quelques instants puis actualise la page"}
                          <br />
                          <span
                            className="flex justify-start text-blue-500"
                            onClick={() => setView(!view)}
                          >
                            {view ? (
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                className="mr-1"
                              />
                            ) : (
                              <FontAwesomeIcon icon={faEye} className="mr-1" />
                            )}
                            {view
                              ? "Cacher les détails"
                              : "Afficher les détails"}
                          </span>
                          {view && (
                            <span className="flex justify-start text-sm italic text-gray-500">
                              {String(erreur[1])}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <Link
                    to="/Parametres"
                    onClick={() => window.location.reload()}
                  >
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-300 sm:ml-3 sm:w-auto"
                    >
                      Actualiser la page
                    </button>
                  </Link>
                  <button
                    type="button"
                    onClick={() => setErreur([false, null])}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Fermer
                  </button>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      )}
      {supp[0] === true && (
        <Dialog
          open={supp[0]}
          onClose={() => setSupp([false, "", ""])}
          className="relative z-10"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-all"
          />

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-200 sm:mx-0 sm:h-10 sm:w-10">
                      <FontAwesomeIcon icon={faTrashCan} />
                    </div>
                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => setSupp([false, "", ""])}
                      >
                        <span className="sr-only">Close</span>
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Supprimer{" "}
                        {supp[1] === "arret"
                          ? "l'arrêt"
                          : supp[1] === "ligne"
                            ? "la ligne : "
                            : "toutes les données"}{" "}
                        {supp[2]} ?
                      </DialogTitle>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Veux-tu vraiment supprimer{" "}
                          {supp[1] === "arret"
                            ? "cet arrêt"
                            : supp[1] === "ligne"
                              ? "cette ligne"
                              : "toutes tes données"}{" "}
                          ?
                        </p>
                      </div>
                      <div
                        id="bouton"
                        className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
                      >
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                          onClick={async () => {
                            supp[1] === "arret"
                              ? handleDeletearret(supp[2])
                              : supp[1] === "ligne"
                                ? handleDeleteligne(supp[2])
                                : localStorage.clear();

                            document.getElementById("bouton")!.innerHTML = `
                            <button 
                              disabled 
                              type="button" 
                              class="text-white bg-red-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2inline-flex items-center"
                            >
                              <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                              </svg>
                              En cours...
                            </button>
                          `;
                            if (supp[1] === "data") {
                              try {
                                if (
                                  !["iPhone", "iPad", "Mac"].includes(
                                    navigator.userAgent,
                                  ) &&
                                  navigator.storage &&
                                  (await readFile("routes.json")) &&
                                  (await readFile("shapes.json")) &&
                                  (await readFile("stops.json"))
                                ) {
                                  await deleteFile("routes.json");
                                  await deleteFile("shapes.json");
                                  await deleteFile("stops.json");
                                }
                              } catch (ERRfatal) {
                                console.error(ERRfatal);
                              } finally {
                                navigate("/");
                              }
                            } else {
                              setSupp([false, "", ""]);
                            }
                          }}
                        >
                          Supprimer
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={() => setSupp([false, "", ""])}
                        >
                          Annuler
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      )}
      <section id="Options">
        {Object.keys(options).map((key) => (
          <div key={key}>
            <Switch
              checked={options[key as keyof OptionsType]}
              onChange={(value) =>
                handleOptionChange(key as keyof OptionsType, value)
              }
              className={classNames(
                options[key as keyof OptionsType]
                  ? "bg-indigo-600"
                  : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                className={classNames(
                  options[key as keyof OptionsType]
                    ? "translate-x-5"
                    : "translate-x-0",
                  "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                )}
              >
                <span
                  className={classNames(
                    options[key as keyof OptionsType]
                      ? "opacity-0 duration-100 ease-out"
                      : "opacity-100 duration-200 ease-in",
                    "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity",
                  )}
                  aria-hidden="true"
                >
                  <svg
                    className="h-3 w-3 text-gray-400"
                    fill="none"
                    viewBox="0 0 12 12"
                  >
                    <path
                      d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span
                  className={classNames(
                    options[key as keyof OptionsType]
                      ? "opacity-100 duration-200 ease-in"
                      : "opacity-0 duration-100 ease-out",
                    "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity",
                  )}
                  aria-hidden="true"
                >
                  <svg
                    className="h-3 w-3 text-indigo-600"
                    fill="currentColor"
                    viewBox="0 0 12 12"
                  >
                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                  </svg>
                </span>
              </span>
            </Switch>
            <span className="ml-2">
              {key === "modele"
                ? " Afficher le modèle du véhicule"
                : key === "exploitant"
                  ? " Afficher l'exploitant du véhicule"
                  : key === "vitesse"
                    ? " Afficher la vitesse actuelle du véhicule"
                    : key === "arret"
                      ? " Afficher l'arrêt actuel/prochain arrêt du véhicule"
                      : key === "UnicLignesFavorites"
                        ? " Afficher uniquement les lignes favorites"
                        : " Ne pas demander le sens lors de la saisie d'un arrêt"}
            </span>
          </div>
        ))}
      </section>
      <section id="Lignes">
        <h2 className="mt-2 text-4xl font-bold">Tes lignes favorites</h2>
        <ul ref={lignesFavoritesRef}>
          {lignesFavorites.length > 0 ? (
            lignesFavorites.map((element) => (
              <li key={element[0]} className="flex w-1/3">
                <button
                  type="button"
                  className="mb-2 me-2 rounded-full bg-gray-800 px-5 py-2.5 text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300"
                  title="Déplacer la ligne"
                >
                  <FontAwesomeIcon icon={faGripVertical} />
                </button>
                {element[0] === "9999" ? (
                  <img
                    className="lignes m-0"
                    src={`./ressources/BRT/BRT.svg`}
                    alt={`Logo : ${element[2]}`}
                    title={`Logo : ${element[2]}`}
                  />
                ) : (
                  <img
                    className="lignes m-0"
                    src={`https://carto-maas.infotbm.com/assets/images/lines/${element[1]}.svg`}
                    alt={`Logo : ${element[2]}`}
                    title={`Logo : ${element[2]}`}
                  />
                )}{" "}
                <button
                  type="button"
                  className="mb-2 ms-2 rounded-full bg-gradient-to-r from-red-400 via-red-500 to-red-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-br focus:outline-none focus:ring-4 focus:ring-red-300"
                  onClick={() => setSupp([true, "ligne", element[2]])}
                  title="Supprimer la ligne"
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
              </li>
            ))
          ) : (
            <li>
              <p>
                Tu n'as pas de lignes favorites
                <br />
                <Link
                  to="/Carte?favoris=true"
                  onClick={() =>
                    setTimeout(() => {
                      window.location.reload();
                    }, 1)
                  }
                >
                  <button
                    type="button"
                    className="mb-2 me-2 rounded-full bg-green-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300"
                  >
                    Ajouter des lignes en favoris
                  </button>
                </Link>
              </p>
            </li>
          )}
        </ul>
      </section>
      <section id="Arrets">
        <h2 className="mt-2 text-4xl font-bold">Tes arrêts favoris</h2>
        <p className="mb-5 italic">
          Pour supprimer certaines directions, écris le nom de l'arrêt dans
          `Prochains Passages` et clique sur le bouton `Gérer les favoris`
        </p>
        <ul ref={arretsFavorisRef}>
          {Object.keys(arretsFavoris).length > 0 ? (
            Object.entries(arretsFavoris).map(([arret, lignes]) => (
              <li key={arret} data-arret={arret} className="w-2/3">
                <button
                  type="button"
                  className="mb-2 me-2 rounded-full bg-gray-800 px-5 py-2.5 text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300"
                  title="Déplacer l'arrêt"
                >
                  <FontAwesomeIcon icon={faGripVertical} />
                </button>
                <span className="inline-flex w-1/3 align-middle">{arret}</span>{" "}
                <button
                  type="button"
                  className="mb-2 ms-2 rounded-full bg-gradient-to-r from-red-400 via-red-500 to-red-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-br focus:outline-none focus:ring-4 focus:ring-red-300"
                  onClick={() => setSupp([true, "arret", arret])}
                  title="Supprimer l'arrêt"
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
                <ul>
                  {lignes.map((ligne, index) => {
                    const element = JSON.parse(ligne);
                    return (
                      <li key={index}>
                        <img
                          className="lignes m-2 inline-flex"
                          src={`https://carto-maas.infotbm.com/assets/images/lines/${element[0][0]}.svg`}
                          alt={`Logo : ${element[0][1]}`}
                        />{" "}
                        <FontAwesomeIcon icon={faCircleRight} fill="#74C0FC" />{" "}
                        {element[1][1]}
                      </li>
                    );
                  })}
                </ul>
              </li>
            ))
          ) : (
            <li>
              <p>
                Tu n'as pas d'arrêts favoris
                <br />
                <Link
                  to="/ProchainsPassages"
                  onClick={() =>
                    setTimeout(() => {
                      window.location.reload();
                    }, 1)
                  }
                >
                  <button
                    type="button"
                    className="mb-2 me-2 rounded-full bg-green-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300"
                  >
                    Ajouter des arrêts en favoris
                  </button>
                </Link>
              </p>
            </li>
          )}
        </ul>
      </section>
      {!isSafari && navigator.storage && Object.keys(OPFS).length !== 0 && (
        <section id="OPFS">
          <div className="sm:pr-6 lg:pr-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h2 className="mt-3 text-4xl font-bold">
                  Gérer les fichiers téléchargés
                </h2>
                <p className="mt-2 text-sm text-gray-700">
                  Les fichiers téléchargés permettent de ne pas les télécharger
                  à chaque utilisation, donc une accélération considérable lors
                  de l'utilisation du site 😉
                </p>
                <p>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    style={{ color: "red" }}
                  />{" "}
                  <i>signifie que la mise à jour du fichier est recommandée</i>
                </p>
              </div>
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                {maj[1] === "tlm" ? (
                  <>
                    <div className="text-center">
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          className="inline h-8 w-8 animate-spin fill-red-400 text-gray-200"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="block rounded-md bg-gray-400 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                      disabled
                    >
                      Mettre à jour tous les fichiers
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => setMaj([true, "tlm"])}
                  >
                    Mettre à jour tous les fichiers
                  </button>
                )}
              </div>
            </div>
            <div className="my-5 flow-root">
              <div className="-my-2 overflow-x-auto lg:-mx-4">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden rounded-lg shadow ring-1 ring-black ring-opacity-5">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Nom du fichier
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Description
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Date de mise à jour
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          >
                            Option
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {people.map((person) => (
                          <tr key={person.name}>
                            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {person.name}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500">
                              {person.description}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500">
                              {person.necessaire && (
                                <FontAwesomeIcon
                                  icon={faCircleExclamation}
                                  style={{ color: "red" }}
                                  className="mr-2"
                                />
                              )}
                              {person.maj}
                            </td>
                            <td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              {maj[1] === person.name.split(".")[0] ? (
                                <>
                                  <div className="text-center">
                                    <div role="status">
                                      <svg
                                        aria-hidden="true"
                                        className="inline h-8 w-8 animate-spin fill-red-400 text-gray-200"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                          fill="currentFill"
                                        />
                                      </svg>
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </div>
                                  <span className="text-gray-400 hover:text-indigo-900">
                                    Mettre à jour
                                  </span>
                                </>
                              ) : (
                                <span
                                  className="text-indigo-600 hover:text-indigo-900"
                                  onClick={() =>
                                    setMaj([true, person.name.split(".")[0]])
                                  }
                                >
                                  Mettre à jour
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <section id="Autre">
        <h2 className="mt-2 text-4xl font-bold">Autre</h2>
        <Link
          to="https://kylou.fr/#/TBpM"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            type="button"
            className="mb-2 me-2 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-bl focus:outline-none focus:ring-4 focus:ring-cyan-300 dark:focus:ring-cyan-800"
          >
            À propos de TBpM <FontAwesomeIcon icon={faUpRightFromSquare} />
          </button>
        </Link>

        <button
          type="button"
          className="me-2 mt-2 rounded-lg bg-gradient-to-r from-red-400 via-red-500 to-red-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-br focus:outline-none focus:ring-4 focus:ring-red-300"
          onClick={() => setSupp([true, "data", ""])}
        >
          Supprimer toutes les données
        </button>
      </section>
    </main>
  );
};

export default LAPage;
