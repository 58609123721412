import { motion } from "framer-motion";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const App = () => {
  const navigate = useNavigate();
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const titreAnime = {
    hidden: { opacity: 0, y: 20 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.05,
      },
    }),
  };
  const texteAnime = {
    hidden: { opacity: 0 },
    visible: (i: number) => ({
      opacity: 1,
      transition: {
        delay: i * 0.025,
      },
    }),
  };

  useEffect(() => {
    setTimeout(() => {
      const Site = localStorage.getItem("Site");

      if (
        (!isSafari && navigator.storage && localStorage.length !== 5) ||
        ((isSafari || !navigator.storage) && localStorage.length !== 4) ||
        Site === null ||
        parseInt(Site) < 810
      ) {
        navigate("/Init");
      } else {
        navigate("/Accueil");
      }
    }, 1500);
  }, [navigate, isSafari]);

  return (
    <main>
      <motion.img
        src="./favicon.png"
        alt="Logo : T'es Bon pour Marcher (TBpM)"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
        className="mx-auto mt-10 block rounded-lg"
      />
      <h1
        className={`mt-5 flex justify-center text-5xl font-extrabold leading-none`}
      >
        {"TBpM".split("").map((char, index) => (
          <motion.span
            key={index}
            custom={index}
            initial="hidden"
            animate="visible"
            variants={titreAnime}
          >
            {char}
          </motion.span>
        ))}
      </h1>
      <p className="mt-14 flex justify-center leading-none">
        {"Une autre vision des transports".split("").map((char, index) => (
          <motion.span
            key={index}
            custom={index}
            initial="hidden"
            animate="visible"
            variants={texteAnime}
          >
            {char === " " ? "\u00A0" : char}
          </motion.span>
        ))}
      </p>
    </main>
  );
};

export default App;
